import React, { useState } from 'react';
import { Button, Container, Grid, Header } from 'semantic-ui-react';

import CarRegForm from '../components/AdvertiseCar/CarRegForm';
import CarDetailPreview from '../components/AdvertiseCar/CarDetailPreview';
import CarDetailForm from '../components/AdvertiseCar/CarDetailForm';
import CarSpecs from '../components/AdvertiseCar/CarSpecs';
import CarImages from '../components/AdvertiseCar/CarImages';
import CarDescription from '../components/AdvertiseCar/CarDescription';
import CarAdvertPreview from '../components/AdvertiseCar/CarAdvertPreview';
import AdvertPayment from '../components/AdvertiseCar/AdvertPayment';
import Thanks from '../components/AdvertiseCar/Thanks';
import AdvertiserInfo from '../components/AdvertiseCar/AdvertiserInfo';

const ADVERT_STEPS = {
  REG_FORM: 1,
  DETAIL_FORM: 2,
  CAR_DETAIL_PREVIEW: 3,
  CAR_SPECS: 4,
  CAR_IMAGE: 5,
  CAR_DESCRIPTION: 6,
  ADVERTISER_INFO: 7,
  ADVERT_PREVIEW: 8,
  ADVERT_PAYMENT: 9,
  THANKS: 10,
};

export default () => {
  const [advertiseStep, setAdvertiseStep] = useState(ADVERT_STEPS.REG_FORM);

  const goTo = (step) => {
    setAdvertiseStep(advertiseStep + step);
  };

  switch (advertiseStep) {
    case ADVERT_STEPS.REG_FORM:
      return (
        <Container className='mt-2 advertise step1'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarRegForm goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.DETAIL_FORM:
      return (
        <Container className='mt-2 advertise step2 jarvis-failed'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarDetailForm goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.CAR_DETAIL_PREVIEW:
      return (
        <Container className='mt-2 advertise step2'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarDetailPreview goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.CAR_SPECS:
      return (
        <Container className='mt-2 advertise step3'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarSpecs goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.CAR_IMAGE:
      return (
        <Container className='mt-2 advertise'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarImages goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.CAR_DESCRIPTION:
      return (
        <Container className='mt-2 advertise'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <CarDescription goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.ADVERTISER_INFO:
      return (
        <Container className='mt-2 advertise'>
          <Header as='h1' className='py-1'>
            Sell your car on carmart
          </Header>
          <AdvertiserInfo goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.ADVERT_PREVIEW:
      return (
        <Container className='mt-2 step6'>
          <Grid columns={2}>
            <Grid.Column>
              <Header as='h1' className='py-1'>
                Car Listing Preview
              </Header>
              <p>Please check your ad and click approve</p>
              <p>Click back to make any changes</p>
            </Grid.Column>
            <Grid.Column className='approve'>
              <Button onClick={() => goTo(1)}>Proceed</Button>
            </Grid.Column>
          </Grid>

          <CarAdvertPreview goTo={goTo} />
        </Container>
      );
    case ADVERT_STEPS.ADVERT_PAYMENT:
      return <AdvertPayment goTo={goTo} />;
    case ADVERT_STEPS.THANKS:
      return <Thanks />;
    default:
      return <></>;
  }
};
