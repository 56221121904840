import React from "react";
import StripeCheckout from "react-stripe-checkout";

export default ({ price, disabled, onSuccess }) => {
  return (
    <div
      style={
        disabled
          ? { cursor: "not-allowed", ...styles.container }
          : styles.container
      }
    >
      <div style={disabled ? { pointerEvents: "none" } : {}}>
        <StripeCheckout
          token={onSuccess}
          stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
          name="Carmart"
          description="Britain's Used Car Comparison Site"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGkNG8YmeYhSZoLIbWJwwiHln_A97fQOI30A&usqp=CAU"
          amount={price * 100}
          currency="GBP"
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: 200,
    width: 200,
    marginTop: 10,
  },
};
