import React, { useState } from "react";
import { Form, Button, Header } from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { isEmailValid } from "../../utils/functions";

export default ({ goTo }) => {
  const { newCarDetail, handleFieldUpdate } = useNewCarDetail();

  const [validationErr, setValidationErr] = useState({});

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {};

    if (!newCarDetail.advertiser.firstName) {
      errors.phone = "Please input your first name";
      isValid = false;
    }

    if (!newCarDetail.advertiser.lastName) {
      errors.phone = "Please input your last name";
      isValid = false;
    }

    if (!newCarDetail.advertiser.address) {
      errors.phone = "Please input your address";
      isValid = false;
    }

    if (!newCarDetail.advertiser.phone) {
      errors.phone = "Please input the phone number";
      isValid = false;
    }

    if (!newCarDetail.advertiser.email) {
      errors.email = "Please input your email";
      isValid = false;
    }

    if (
      !!newCarDetail.advertiser.email &&
      !isEmailValid(newCarDetail.advertiser.email)
    ) {
      errors.email = "Please input the valid email";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (checkValidationandShowErr()) {
      goTo(1);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group widths="equal">
        <Form.Field>
          <Header as="h4">First name</Header>
          <Form.Input
            size="big"
            placeholder="Your first name here"
            onChange={e =>
              handleFieldUpdate("advertiser.firstName", e.target.value)
            }
            error={validationErr.firstName}
            value={newCarDetail.advertiser.firstName}
          />
        </Form.Field>
        <Form.Field>
          <Header as="h4">Last name</Header>
          <Form.Input
            size="big"
            placeholder="Your last name here"
            onChange={e =>
              handleFieldUpdate("advertiser.lastName", e.target.value)
            }
            error={validationErr.lastName}
            value={newCarDetail.advertiser.lastName}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <Header as="h4">Telephone Number for Enquiries</Header>
        <Form.Input
          size="big"
          placeholder="Your phone number here"
          onChange={e => handleFieldUpdate("advertiser.phone", e.target.value)}
          error={validationErr.phone}
          value={newCarDetail.advertiser.phone}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h4">Email Address for Enquiries</Header>
        <Form.Input
          size="big"
          placeholder="Your Email here"
          onChange={e => handleFieldUpdate("advertiser.email", e.target.value)}
          error={validationErr.email}
          value={newCarDetail.advertiser.email}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h4">Address</Header>
        <Form.Input
          size="big"
          placeholder="Your address here"
          onChange={e =>
            handleFieldUpdate("advertiser.address", e.target.value)
          }
          error={validationErr.address}
          value={newCarDetail.advertiser.address}
        />
      </Form.Field>

      <Button type="back" onClick={() => goTo(-1)}>
        Back
      </Button>
      <br />
      <Button type="submit">Confirm</Button>
    </Form>
  );
};
