import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import CarsProvider from "./contexts/CarsContext";
import QueryProvider from "./contexts/QueryContext";
import AuthProvider from "./contexts/AuthContext";
import NewCarProvider from "./contexts/NewCarContext";

import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <CarsProvider>
          <QueryProvider>
            <NewCarProvider>
              <App />
            </NewCarProvider>
          </QueryProvider>
        </CarsProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
