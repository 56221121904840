import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  Header,
  Button,
  Divider,
  Message,
  Grid,
} from "semantic-ui-react";
import { Redirect, Link, useHistory } from "react-router-dom";

import CarHorizontal2 from "../components/CarHorizontal2";
import { useAuth } from "../contexts/AuthContext";
import { getSavedCars } from "../utils/api";

const SignIn = (props) => {
  const history = useHistory();
  const { auth } = useAuth();
  const [savedCars, setSavedCars] = useState([]);
  const [selectedCars, setSelectedCars] = useState([]);

  useEffect(() => {
    getSavedCars(auth.token)
      .then((cars) => setSavedCars(cars))
      .catch((err) => console.log(err));
  }, [auth.token]);

  const startCompare = () => {
    history.push({
      pathname: "/compare",
      state: {
        cars: selectedCars,
      },
    });
  };

  const handleChange = (id, distance, checked) => {
    let cars = [];
    if (checked) {
      cars = [...selectedCars, { id, distance }];
    } else {
      cars = selectedCars.filter((v) => v.id !== id);
    }

    setSelectedCars(cars);
  };

  if (!auth.token) {
    return <Redirect to="/sign-in" />;
  }

  return (
    <Container className="pt-5" style={{ paddingBottom: 100 }}>
      <Header as="h1" className="text-center">
        {savedCars.length} vehicles saved
      </Header>
      <Grid columns={3}>
        {savedCars.map((car, idx) => {
          const chkBoxDisabled =
            selectedCars.length > 3 && !selectedCars.includes(car._id);
          return (
            <Grid.Column key={`saved-car-${idx}`}>
              <CarHorizontal2
                detail={car}
                onChange={handleChange}
                disabled={chkBoxDisabled}
                cars={savedCars}
                setCars={setSavedCars}
              />
            </Grid.Column>
          );
        })}
      </Grid>
      {selectedCars.length > 1 && (
        <div className="w-100 d-flex justify-content-center mt-4">
          <Button primary size="big" onClick={startCompare}>
            Compare now
          </Button>
        </div>
      )}
    </Container>
  );
};

export default SignIn;
