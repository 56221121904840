import React, { useEffect, useRef, useState } from "react";
import {
  Dimmer,
  Loader,
  Image,
  Button,
  Grid,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { uploadImages } from "../../utils/api";

const CarImage = ({ imgSrc }) => {
  return (
    <div className="photo">
      {!!imgSrc && <Image src={imgSrc} alt="" />}
      {!imgSrc && <Icon name="plus" size="large" color="grey" />}
    </div>
  );
};

export default ({ goTo }) => {
  const { newCarDetail, handleFieldUpdate } = useNewCarDetail();

  const imageFileRef = useRef();
  const [uploading, setUploading] = useState(false);
  const [err, setErr] = useState(false);
  useEffect(() => {
    const imgURLs = Array.from(newCarDetail.imageFiles)
      .slice(0, 20)
      .map(file => URL.createObjectURL(file));
    if (imgURLs.length) {
      handleFieldUpdate("images", imgURLs);
    }
  }, [newCarDetail.imageFiles]);

  const handleFileChange = e => {
    handleFieldUpdate("imageFiles", e.target.files);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (uploading) return;
    if (!newCarDetail.images.length) {
      setErr(true);
      return;
    }

    setUploading(true);
    uploadImages(newCarDetail.imageFiles)
      .then(data => {
        setUploading(false);
        handleFieldUpdate("images", data.fileNames);
        goTo(1);
      })
      .catch(err => console.log(err))
      .finally(() => setUploading(false));
  };

  return (
    <>
      <Dimmer active={uploading} inverted>
        <Loader />
      </Dimmer>
      <Header as="h4">Upload your photos</Header>
      {err && <Message negative>Please select your images</Message>}
      <Header as="h6">Photos of your car</Header>
      <hr />
      <p>Your advert can contain up to 20 photos</p>
      <label>Photos:</label>{" "}
      <Button
        className="upload-photos my-5 ml-4"
        onClick={() => imageFileRef.current.click()}
      >
        <Icon name="camera" />
        Add photos
      </Button>
      <input
        type="file"
        ref={imageFileRef}
        style={{ display: "none" }}
        multiple
        accept="image/*"
        onChange={handleFileChange}
      />
      <Grid className="photos-container" columns={5}>
        {newCarDetail.images.map((img, idx) => (
          <Grid.Column key={`car-img-${idx}`}>
            <CarImage imgSrc={img} />
          </Grid.Column>
        ))}
      </Grid>
      <div
        className="mt-5 d-flex justify-content-end cursor-pointer"
        onClick={() => goTo(1)}
      >
        Add images later
      </div>
      <div className="mt-1 d-flex justify-content-between">
        <Button type="back" className="ml-0" onClick={() => goTo(-1)}>
          Back
        </Button>
        <Button type="submit" className="mr-0" onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </>
  );
};
