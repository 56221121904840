import React, { useState } from "react";
import {
  Container,
  Form,
  Header,
  Button,
  Divider,
  Message,
} from "semantic-ui-react";
import { Redirect, Link, useHistory } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import { isEmailValid } from "../utils/functions";
import { login } from "../utils/api";

const SignIn = (props) => {
  const history = useHistory();
  const { auth, setAuthCtx } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formBusy, setFormBusy] = useState(false);
  const [validationErr, setValidationErr] = useState({
    email: false,
    password: false,
    form: false,
  });

  const checkValidationandShowErr = () => {
    let isValid = true;

    let errors = {
      email: false,
      password: false,
    };

    if (formData.email === "") {
      errors.email = "Please input the email";
      isValid = false;
    } else if (!isEmailValid(formData.email)) {
      errors.email = "Please input the valid email";
      isValid = false;
    }

    if (formData.password === "") {
      errors.password = "Please input the password";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleChange = (e, { name }) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (formBusy) return;

    if (checkValidationandShowErr()) {
      setFormBusy(true);
      login(formData)
        .then((res) => {
          if (res.status === "success") {
            setAuthCtx(res);
            history.goBack();
          } else {
            setValidationErr({
              ...validationErr,
              form: res.message,
            });
          }
        })
        .catch((err) =>
          setValidationErr({ ...validationErr, form: "Something went wrong!" })
        )
        .finally(() => {
          setFormBusy(false);
        });
    }
  };

  if (auth.token) {
    return <Redirect to="/saved" />;
  }

  return (
    <Container className="py-5">
      <div style={{ maxWidth: 400 }} className="mx-auto my-5 py-5">
        <Header as="h1" className="text-center">
          Make the most of Carmart
        </Header>
        <p className="text-center">
          It's completely <b>free</b>
        </p>
        <Form loading={formBusy}>
          <Form.Input
            className="mt-4"
            fluid
            value={formData.email}
            error={validationErr.email}
            name="email"
            onChange={handleChange}
            placeholder="Email"
          />
          <Form.Input
            className="mt-4"
            fluid
            value={formData.password}
            error={validationErr.password}
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
          />
          <Message
            visible={!!validationErr.form}
            error
            content={validationErr.form}
          />
        </Form>
        <Button fluid color="red" className="mt-2" onClick={handleLogin}>
          Login
        </Button>
        <Divider horizontal>OR</Divider>
        <div className="d-flex flex-column align-items-center mt-2">
          {/* <Link to="forgot-pwd">Frogot password?</Link> */}
          <p className="mt-1">
            Don't you have an account? <Link to="register">Register</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
