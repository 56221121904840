import React, { createContext, useState, useEffect, useContext } from "react";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    loading: true,
    token: null,
    isAdmin: false,
    id: null,
  });

  const setAuthCtx = data => {
    setAuth({
      id: data.id,
      token: data.token,
      isAdmin: data.isAdmin,
      loading: false,
    });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      token: JSON.parse(window.localStorage.getItem("carmart-auth"))?.token,
      id: JSON.parse(window.localStorage.getItem("carmart-auth"))?.id,
      isAdmin: JSON.parse(window.localStorage.getItem("carmart-auth"))?.isAdmin,
    });
  }, []);

  useEffect(() => {
    window.localStorage.setItem("carmart-auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <authContext.Provider value={{ auth, setAuthCtx }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);
export default AuthProvider;
