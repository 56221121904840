import React, { useState } from "react";
import { Icon, Popup, Form, Header, Grid, Checkbox } from "semantic-ui-react";

import { useQuery } from "../contexts/QueryContext";

export default ({ title, type, options, className, active, onClick, name }) => {
  const [icon, setIcon] = useState("angle down");
  const [chkBoxes, setChkBoxes] = useState(new Array(100).fill(false));
  const [maxOptions, setMaxOptions] = useState(options);
  const { query, setQuery } = useQuery();

  const popupRef = React.useRef();

  const handleClick = () => {
    if (icon === "angle down") setIcon("angle up");
    else setIcon("angle down");

    onClick();
  };

  const handleInputChange = (e, { name, value }) => {
    if (name === "make")
      setQuery({ ...query, [name]: value, model: "", variant: "" });
    else if (name === "model")
      setQuery({ ...query, [name]: value, variant: "" });
    else setQuery({ ...query, [name]: value });
  };

  const handleChkBoxChange = (idx, value) => (e, { checked }) => {
    let temp = [...chkBoxes];
    temp[idx] = checked;

    setChkBoxes(temp);

    console.log(name, query[name]);
    let selected = [...query[name]];
    if (checked) selected.push(value);
    else {
      let index = selected.indexOf(value);
      if (index !== -1) {
        selected.splice(index, 1);
      }
    }

    setTimeout(() => setIcon("angle down"), 200);

    setQuery({ ...query, [name]: selected });
  };

  const handleRangeChange = (e, { name, value }) => {
    if (name.includes("min")) {
      let temp = maxOptions.filter(v => v.value > value);
      setMaxOptions(temp);
    }

    setQuery({ ...query, [name]: value });
  };

  let value = "Any";
  if (type === "range" && !!query[`max${name}`]) {
    value = query[`min${name}`] + " - " + query[`max${name}`];
  }
  if (type === "select" && !!query[name]) value = query[name];
  if (type === "popup" && query[name].length) {
    if (query[name].length > 1) value = "Multiple";
    else value = query[name][0];
  }

  return (
    <>
      <div className="search-filter">
        <div
          className={`d-flex align-items-center justify-content-between ${
            !!className ? className : ""
          }`}
          onClick={handleClick}
          ref={popupRef}
        >
          <h5 className="m-0">{title}</h5>
          <div className="d-flex">
            <p className="m-0">{value}</p>
            <Icon name={icon} size="large" />
          </div>
        </div>

        {/* Select options */}
        {type === "select" && icon === "angle up" && (
          <Form.Select
            className="search-filter--options my-1"
            fluid
            options={options}
            placeholder={title}
            name={name}
            onChange={handleInputChange}
            value={query[name]}
            search
          />
        )}

        {/* Range options from, to  */}
        {type === "range" && icon === "angle up" && (
          <>
            <Form.Select
              placeholder="Min"
              label="Min"
              name={`min${name}`}
              className="mb-1 search-filter--options"
              onChange={handleRangeChange}
              options={options}
              value={query[`min${name}`]}
            />
            <Form.Select
              placeholder="Max"
              label="Max"
              name={`max${name}`}
              options={maxOptions}
              className="search-filter--options"
              onChange={handleInputChange}
              value={query[`max${name}`]}
            />
          </>
        )}
      </div>

      {/* Modal Options */}
      <Popup
        open={type === "popup" && icon === "angle up" && active === title}
        position="right center"
        context={popupRef}
        flowing
        style={{ minWidth: 450 }}
      >
        <Header>Select {title}</Header>
        <Grid columns={3}>
          {options &&
            options.map((v, idx) => (
              <Grid.Column key={v.key}>
                <Checkbox
                  label={v.text}
                  checked={chkBoxes[idx]}
                  onChange={handleChkBoxChange(idx, v.value)}
                />
              </Grid.Column>
            ))}
        </Grid>
      </Popup>
    </>
  );
};
