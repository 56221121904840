import React, { useState } from "react";
import { Grid, Accordion, Icon } from "semantic-ui-react";

const getValidValueForInt = (value, unit = "") => {
  if (value > 0) return value + " " + unit;
  return "N.A";
};

export default ({ info }) => {
  const options = [
    "centralLocking",
    "keylessEntryStart",
    "startButton",
    "controlCircuit",
    "electricWindows",
    "powerSteering",
    "cruiseControl",
    "airConditioning",
    "dual_zoneClimateControl",
    "parkingSensors",
    "reverseCamera",
    "parkingLot",
    "electricParkingBrake",
    "start_stopSystem",
    "seatHeightAdjustment",
    "lumbarSupport",
    "electricSeatAdjustment",
    "heatedSeats",
    "ventilatedSeats",
    "sportsSeats",
    "leatherSteeringWheel",
    "adjustableSteeringWheel",
    "heatedSteeringWheel",
    "leatherSeats",
    "rearHeadrests",
    "foldingRearSeats",
    "slidingRearSeat",
    "centralArmrest",
    "auto_dimRearViewMirror",
    "readingLight",
    "makeupMirrorWithLight",
    "adjustableDashboardLights",
    "tachometer",
    "trip_odometer",
    "waterTemperature",
    "outdoorTemperature",
    "onboardComputer",
    "audioSystem",
    "digitalRadio_DAB_",
    "steeringWheelControlsForAudio",
    "audioInput",
    "navigationSystem",
    "bluetooth",
    "intermittentWindscreenWipers",
    "alloyWheels",
    "sliding_tiltingRoof",
    "panoramicRoof",
    "roofRails",
    "metallicPaint",
    "colorKeyedBumpers",
    "tintedGlass",
    "rearPrivacyGlass",
    "electricMirrors",
    "foldingExteriorMirrors",
    "automaticDimmingExteriorMirrors",
    "turnSignalInMirrors",
    "frontFogLights",
    "automaticHeadlights",
    "xenonHeadlights",
    "ledHeadlight",
    "ledRearLights",
    "parkingLights",
    "headlightWashers",
    "burglarAlarm",
    "ABS",
    "brakeForceDistribution",
    "brakeAssist",
    "emergencyBrakeAssist",
    "collisionAvoidanceSystem",
    "stabilityControl",
    "tractionControl",
    "lockingDifferential",
    "airbagDriver",
    "passengerAirbag",
    "sideAirbags",
    "head_curtainAirbags",
    "driversKneeAirbag",
    "hillAssist",
    "laneAssist",
    "blindSpotAssist",
    "fatigueSensor",
    "tirePressureSensor",
    "citySafetySystem",
    "nightVisionWithPedestrianRecognition",
    "highBeamAssist",
    "trafficSignRecognition",
    "collisionWarningSystem",
    "self_levellingSuspension",
  ];
  const [activeIndex, setActiveIdx] = useState(-1);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIdx(newIndex);
  };

  const getOptionValue = (key, value) => {
    let formattedKey = key.replace(/([A-Z])/g, " $1").replace(/([_])/g, " ");

    formattedKey =
      formattedKey.charAt(0).toUpperCase() +
      formattedKey.slice(1).toLowerCase();
    if (value === "N.A") return null;

    return (
      <Grid.Column>
        <li className="dots">
          <span>
            <Icon name="check circle" color="green" />
            {formattedKey}
          </span>
          <span>{value}</span>
        </li>
      </Grid.Column>
    );
  };

  const getTechValue = (key, value) => {
    if (typeof value === "string" && value.toUpperCase().includes("N.A"))
      return null;

    return (
      <Grid.Column>
        <li className="dots">
          <span>
            <Icon name="check circle" color="green" />
            {key}
          </span>
          <span>{value}</span>
        </li>
      </Grid.Column>
    );
  };

  if (!info?.topSpeed) return <></>;

  return (
    <Accordion className="text-16 specs_accordion">
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleAccordionClick}
        className="d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <Icon name="settings" className="mr-1" />
          <h4 className="pd--heading__secondary p-0">Performance</h4>
        </div>
        <Icon name={activeIndex === 0 ? "angle up" : "angle down"} />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <Grid columns={1} className="pr-5">
          {getTechValue("Top Speed", getValidValueForInt(info.topSpeed, "MPH"))}
          {getTechValue("Max Power (BHP)", getValidValueForInt(info.maxBHP))}
          {getTechValue(
            "Acceleration (0 - 62 MPH)",
            getValidValueForInt(info.accelerationSeconds, "seconds"),
          )}
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleAccordionClick}
        className="d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <Icon name="settings" className="mr-1" />
          <h4 className="pd--heading__secondary p-0">Efficiency</h4>
        </div>
        <Icon name={activeIndex === 1 ? "angle up" : "angle down"} />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <Grid columns={1} className="pr-5">
          {getTechValue(
            "City Fuel Consumption",
            getValidValueForInt(info.consumption.cityFuel, "mpg"),
          )}
          {getTechValue(
            "Extra Urban Fuel Consumption",
            getValidValueForInt(info.consumption.extraUrban, "mpg"),
          )}
          {getTechValue(
            "Combined Fuel Consumption",
            getValidValueForInt(info.consumption.combined, "mpg"),
          )}
          {getTechValue(
            "CO2 Emissions (g/km)",
            getValidValueForInt(info.co2Emssion, "g/km"),
          )}
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleAccordionClick}
        className="d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <Icon name="calculator" className="mr-1" />
          <h4 className="pd--heading__secondary p-0">Convenience</h4>
        </div>
        <Icon name={activeIndex === 2 ? "angle up" : "angle down"} />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <p className="text-12">
          Below are the standard options and specifications for the car
          generally and are an indication of what you can expect. They are not
          necessarily the exact specifications specific to the car for sale,
          please confirm the full specification of this car with the seller
        </p>
        <Grid columns={2} relaxed>
          {options.map(v => getOptionValue(v, info[v]))}
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleAccordionClick}
        className="d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <Icon name="shield" className="mr-1" />
          <h4 className="pd--heading__secondary p-0">Full specification</h4>
        </div>
        <Icon name={activeIndex === 3 ? "angle up" : "angle down"} />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <Grid columns={2} className="pr-5">
          {getTechValue("Number of Doors", getValidValueForInt(info.doorCnt))}
          {getTechValue("Fuel Type", info.fuelType)}
          {getTechValue("Body Type", info.bodyType)}
          {getTechValue(
            "Cylinder Capacity",
            getValidValueForInt(info.cylinderCapacity, "cc"),
          )}
          {getTechValue("Transmission", info.transmission)}
          {getTechValue("Number of Seats", getValidValueForInt(info.seatCnt))}
          {getTechValue("Drivetrain", info.driveTrain)}
          {getTechValue(
            "Fuel Tank Capacity (litres)",
            getValidValueForInt(info.tankCapacity, "litres"),
          )}
          {getTechValue(
            "Maximum Torque (nm)",
            getValidValueForInt(info.maxTorque, "nm"),
          )}
          {getTechValue(
            "Number of Cylinders",
            getValidValueForInt(info.cylinderCnt),
          )}
          {getTechValue(
            "Valves Per Cylinder",
            getValidValueForInt(info.valvesPerCylinder),
          )}
          {getTechValue("Fuel System", info.fuel.system)}
          {getTechValue("Valve Control", info.valveControl)}
          {getTechValue("Transmission", info.transmission)}
          {getTechValue("Turbocharged", info.turboCharged)}
          {getTechValue("Catalyst", getValidValueForInt(info.seatCnt))}
          {getTechValue("Number", getValidValueForInt(info.catalyst))}
          {getTechValue("Battery Type", info.battery.sort)}
          {getTechValue(
            "Battery Capacity",
            getValidValueForInt(info.battery.capacity),
          )}
          {getTechValue(
            "Battery Voltage",
            getValidValueForInt(info.battery.voltage),
          )}
          {getTechValue(
            "Battery Range",
            getValidValueForInt(info.battery.range),
          )}
          {getTechValue(
            "Power Consumption",
            getValidValueForInt(info.consumption.power),
          )}
          {getTechValue("Energy label", info.energyLabel)}
          {getTechValue("Front tyre", getValidValueForInt(info.tyreSize.front))}
          {getTechValue("Rear tyre", getValidValueForInt(info.tyreSize.rear))}
        </Grid>
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 4}
        index={4}
        onClick={handleAccordionClick}
        className="d-flex justify-content-between"
      >
        <div className="d-flex align-items-center">
          <Icon name="settings" className="mr-1" />
          <h4 className="pd--heading__secondary p-0">Dimensions</h4>
        </div>
        <Icon name={activeIndex === 4 ? "angle up" : "angle down"} />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 4}>
        <Grid columns={1} className="pr-5 mb-2">
          {getTechValue("Length", getValidValueForInt(info.size.length, "mm"))}
          {getTechValue("Width", getValidValueForInt(info.size.width, "mm"))}
          {getTechValue("Height", getValidValueForInt(info.size.height, "mm"))}
          {getTechValue("Wheelbase", getValidValueForInt(info.Wheelbase, "mm"))}
          {getTechValue(
            "Front track width",
            getValidValueForInt(info.trackWidth.front, "mm"),
          )}
          {getTechValue(
            "Rear track width",
            getValidValueForInt(info.trackWidth.rear, "mm"),
          )}
          {getTechValue(
            "Empty Weight",
            getValidValueForInt(info.emptyWeight, "kg"),
          )}
          {getTechValue(
            "Max Load Weight",
            getValidValueForInt(info.maxLoadCapacity, "kg"),
          )}
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};
