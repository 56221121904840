import React, { useState } from "react";
import { Container, Image, List } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";

import logo from "../assets/img/logo.png";

export default () => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("home");
  const [showMobile, setShowMobile] = useState(false);

  const handleItemClick = (e, { name }) => {
    history.push(`/${name}`);
    setActiveItem(name);
  };

  return (
    <div className="footer">
      <Container>
        <Image src={logo} alt="logo" />

        <div className="d-flex justify-content-between ">
          <List>
            <List.Item>Carmart Group</List.Item>
            <List.Item>
              <Link to="contact">Contact us</Link>
            </List.Item>
            <List.Item>
              <Link to="about">About Carmart</Link>
            </List.Item>
            <List.Item>
              <Link to="careers">Careers</Link>
            </List.Item>
            <List.Item>
              <Link to="investor-info">Investor information</Link>
            </List.Item>
            <List.Item>
              <Link to="privacy">Privacy policies</Link>
            </List.Item>
            <List.Item>
              <Link to="selling-term">Selling terms &amp; conditions</Link>
            </List.Item>
            <List.Item>
              <Link to="disp-ad">Display advertising</Link>
            </List.Item>
          </List>

          <List>
            <List.Item>Products</List.Item>
            <List.Item>
              <Link to="contact">Contact us</Link>
            </List.Item>
            <List.Item>
              <Link to="about">About Carmart</Link>
            </List.Item>
            <List.Item>
              <Link to="careers">Careers</Link>
            </List.Item>
            <List.Item>
              <Link to="investor-info">Investor information</Link>
            </List.Item>
            <List.Item>
              <Link to="privacy">Privacy policies</Link>
            </List.Item>
            <List.Item>
              <Link to="selling-term">Selling terms &amp; conditions</Link>
            </List.Item>
            <List.Item>
              <Link to="disp-ad">Display advertising</Link>
            </List.Item>
          </List>

          <List>
            <List.Item>Products</List.Item>
            <List.Item>
              <Link to="contact">Contact us</Link>
            </List.Item>
            <List.Item>
              <Link to="about">About Carmart</Link>
            </List.Item>
            <List.Item>
              <Link to="careers">Careers</Link>
            </List.Item>
            <List.Item>
              <Link to="investor-info">Investor information</Link>
            </List.Item>
            <List.Item>
              <Link to="privacy">Privacy policies</Link>
            </List.Item>
            <List.Item>
              <Link to="selling-term">Selling terms &amp; conditions</Link>
            </List.Item>
            <List.Item>
              <Link to="disp-ad">Display advertising</Link>
            </List.Item>
          </List>

          <List>
            <List.Item>Products</List.Item>
            <List.Item>
              <Link to="contact">Contact us</Link>
            </List.Item>
            <List.Item>
              <Link to="about">About Carmart</Link>
            </List.Item>
            <List.Item>
              <Link to="careers">Careers</Link>
            </List.Item>
            <List.Item>
              <Link to="investor-info">Investor information</Link>
            </List.Item>
            <List.Item>
              <Link to="privacy">Privacy policies</Link>
            </List.Item>
            <List.Item>
              <Link to="selling-term">Selling terms &amp; conditions</Link>
            </List.Item>
            <List.Item>
              <Link to="disp-ad">Display advertising</Link>
            </List.Item>
          </List>
        </div>
      </Container>
    </div>
  );
};
