import React, { useEffect, useState } from "react";
import { Icon, Image } from "semantic-ui-react";
import { Link, useHistory, withRouter } from "react-router-dom";

import Menus from "../constants/navabr";
import logo from "../assets/img/logo.png";
import ad from "../assets/img/carmart_ad.png";
import { useAuth } from "../contexts/AuthContext";

const NavItem = ({ item, activeItem, onClick }) => (
  <Link
    to={item.path}
    className={activeItem === item.path ? "item active" : "item"}
    onClick={onClick}
  >
    {!!item.icon && (
      <div className="text-center">
        <Icon name={item.icon} />
        <p>{item.title}</p>
      </div>
    )}
    {!item.icon && <p>{item.title}</p>}
  </Link>
);

const Navbar = () => {
  const history = useHistory();
  const { auth, setAuthCtx } = useAuth();
  const [activeItem, setActiveItem] = useState("home");
  const [showHamburger, setShowHamburger] = useState(false);
  const logout = () => {
    setAuthCtx({ token: "" });
    history.push("/sign-in");
  };

  useEffect(() => {
    history.listen((location, action) => {
      let to = location.pathname.split("/")[1];
      setActiveItem(to);
    });
  }, [history]);
  const toggleNavMenu = () => {
    setShowHamburger(!showHamburger);
  };
  const authPart = !!auth.token ? (
    <NavItem
      item={{ title: "Sign-out", path: "", icon: "user circle" }}
      onClick={logout}
    />
  ) : (
    <NavItem
      item={{
        title: "Sign-in/Register",
        path: "/sign-in",
        icon: "user circle",
      }}
      onClick={toggleNavMenu}
    />
  );

  return (
    <div className="navbar">
      <div style={{ flex: 3 }}>
        <Link className="nav-logo item" to="/">
          <Image src={logo} alt="logo" />
          <p>Britain's used car comparison site</p>
        </Link>
        <div className="menus">
          {Menus.map(menu => (
            <NavItem item={menu} activeItem={activeItem} key={menu.path} />
          ))}

          {authPart}
        </div>
        {showHamburger && (
          <div className="mobile-menu">
            {Menus.map(menu => (
              <NavItem
                item={menu}
                activeItem={activeItem}
                onClick={toggleNavMenu}
              />
            ))}

            {authPart}
          </div>
        )}
      </div>
      <div className="advert">
        <Link to="/advertise">
          <Image src={ad} alt="" fluid />
        </Link>
      </div>
      <div className="menu-icon" onClick={toggleNavMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
