import React, { useState } from "react";
import { Button, Container, Header, Form } from "semantic-ui-react";
import { toast } from "react-toastify";

import { isEmailValid } from "../utils/functions";
import { sendContactEmail } from "../utils/api";

const ContactUs = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [validationErr, setValidationErr] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [formBusy, setFormBusy] = useState(false);

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {
      firstName: false,
      lastName: false,
      email: false,
      message: false,
    };

    if (formData.firstName === "") {
      errors.firstName = "Please input your first name";
      isValid = false;
    }
    if (formData.lastName === "") {
      errors.lastName = "Please input your last name";
      isValid = false;
    }
    if (formData.email === "") {
      errors.email = "Please input your email";
      isValid = false;
    } else if (!isEmailValid(formData.email)) {
      errors.email = "Please input the valid email";
      isValid = false;
    }
    if (formData.message === "") {
      errors.message = "Please input the message";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const sendEmail = () => {
    if (formBusy) return;

    if (checkValidationandShowErr()) {
      setFormBusy(true);
      sendContactEmail(formData)
        .then(() => {
          toast.success("✔️ Your message has been sent", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

          setTimeout(() => props.history.push("/"), 800);
        })
        .catch(() => {
          toast.error("❌ Something went wrong.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        })
        .finally(() => setFormBusy(false));
    }
  };

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Container>
      <div className="contact-us">
        <Header as="h1" className="text-center mb-3">
          Contact Us
        </Header>
        <Form loading={formBusy} onSubmit={sendEmail}>
          <Form.Group widths="equal">
            <Form.Input
              label="First Name"
              name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              required
              error={validationErr.firstName}
            />
            <Form.Input
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              error={validationErr.lastName}
            />
          </Form.Group>
          <Form.Input
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={validationErr.email}
          />
          <Form.Input
            label="Phone"
            name="phone"
            onChange={handleChange}
            value={formData.phone}
          />
          <Form.TextArea
            label="Message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            error={validationErr.message}
          />

          <Button primary fluid type="submit">
            Contact
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ContactUs;
