import React from "react";
import { Grid, List, Checkbox, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import LazyImage from "./LazyImage";
import { formatNumber } from "../utils/functions";
import { saveCar } from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const CarHorizontal = ({ detail, onChange, disabled, cars, setCars }) => {
  const { auth } = useAuth();

  const handleChange = (e, { checked }) => {
    e.preventDefault();
    onChange(detail._id, detail.distance, checked);
  };

  const unsave = (e) => {
    e.stopPropagation();
    e.preventDefault();

    saveCar(detail._id, auth?.token)
      .then((response) => {
        let temp = cars.filter((car) => car._id !== detail._id);
        setCars(temp);

        toast.success(response.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch((error) => {
        toast.error("❌ Something went wrong.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  return (
    <Link
      to={{
        pathname: `/detail/${detail._id}`,
        state: {
          distance: detail.distance,
        },
      }}
    >
      <Grid className="car__horizontal2">
        <Grid.Row columns={2}>
          <Grid.Column className="main__img">
            <LazyImage src={detail.images[0]} />
          </Grid.Column>
          <Grid.Column>
            <h4 className="price">£{formatNumber(detail.price)}</h4>
            <h4 className="title">{detail.headline}</h4>
            <div
              className="d-flex align-items-center"
              style={{ color: "black" }}
            >
              <img
                src={require("../assets/img/location.svg")}
                alt=""
                className="mr-1"
              />
              {detail.distance} miles away
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="pt-0">
          <Grid.Column width={16}>
            <List horizontal style={{ color: "#0B3464" }}>
              <List.Item>{detail.mileage} miles</List.Item>
              <List.Item>{detail.year}</List.Item>
              <List.Item>{detail.bodyType}</List.Item>
              <List.Item>{detail.transmission}</List.Item>
              <List.Item>{detail.fuelType}</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{ background: "#3b74b5" }}
          onClick={(e) => e.preventDefault()}
        >
          <Grid.Column
            className="d-flex justify-content-center"
            width={16}
            onClick={(e) => e.preventDefault()}
          >
            <Checkbox
              label="Compare"
              onChange={handleChange}
              disabled={disabled}
            />
            <Button icon="trash alternate" onClick={unsave} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Link>
  );
};

export default CarHorizontal;
