import React, { useState } from "react";
import { Form, Button, Header } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";

export default ({ goTo }) => {
  const { newCarDetail, handleFieldUpdate } = useNewCarDetail();

  const [validationErr, setValidationErr] = useState({});

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {};
    if (!newCarDetail.price) {
      errors.price = "Please input your price";
      isValid = false;
    }

    if (!newCarDetail.headline) {
      errors.headline = "Please input the headline";
      isValid = false;
    }

    if (!newCarDetail.description) {
      errors.description = "Please input the description";
      isValid = false;
    }

    if (!newCarDetail.postcode) {
      errors.postcode = "Please input the postcode";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleSubmit = () => {
    console.log(newCarDetail);
    if (checkValidationandShowErr()) {
      goTo(1);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Field>
        <Header as="h4">Confirm your selling price</Header>
        <Form.Input
          size="big"
          placeholder="Selling price for your car"
          onChange={e => handleFieldUpdate("price", e.target.value)}
          pattern="[0-9]*"
          serror={validationErr.price}
          value={newCarDetail.price}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h4">
          Write a headline, say something about the car to get attention
        </Header>
        <Form.Input
          size="big"
          placeholder="Car headline here"
          onChange={e => handleFieldUpdate("headline", e.target.value)}
          error={validationErr.headline}
          value={newCarDetail.headline}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h4">Write a description about your car</Header>
        <Form.TextArea
          size="big"
          placeholder="Tell something about your car"
          onChange={e => handleFieldUpdate("description", e.target.value)}
          error={validationErr.description}
          value={newCarDetail.description}
        />
      </Form.Field>

      <Form.Field>
        <Header as="h4">Postcode where car can be viewed</Header>
        <Form.Input
          size="big"
          placeholder="Car postcode here"
          onChange={e =>
            handleFieldUpdate("postcode", e.target.value.toUpperCase())
          }
          value={newCarDetail.postcode}
          className="postcode"
          error={validationErr.postcode}
        />
      </Form.Field>

      <Form.Field>
        <Header as="h4">MOT Expiry Date</Header>
        <DateInput
          dateFormat="YYYY-MM-DD"
          value={newCarDetail.motExpiryDate}
          onChange={(e, { value }) => {
            handleFieldUpdate("motExpiryDate", value);
          }}
        />
      </Form.Field>

      <Button type="back" onClick={() => goTo(-1)}>
        Back
      </Button>
      <br />
      <Button type="submit">Confirm</Button>
    </Form>
  );
};
