export default [
  {
    title: "Find a used car",
    path: "/used-cars",
    icon: "",
  },
  {
    title: "Sell my car",
    path: "/advertise",
    icon: "",
  },
  {
    title: "Dealers Area",
    path: "/dealer-area",
    icon: "",
  },
  {
    title: "Value your car",
    path: "/value-car",
    icon: "",
  },
  {
    title: "About us",
    path: "/about-us",
    icon: "",
  },
  {
    title: "Contact us",
    path: "/contact-us",
    icon: "",
  },
  {
    title: "Help me choose a car",
    path: "/help-choose-car",
    icon: "",
  },
  {
    title: "Saved",
    path: "/saved-cars",
    icon: "heart",
  },
];
