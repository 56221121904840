import React, { useEffect, useState } from "react";
import { Container, Grid, Form, Icon, Dimmer, Loader } from "semantic-ui-react";
import { useCookies } from "react-cookie";

import SearchFilter from "../components/SearchFilter";
import CarHorizontal from "../components/CarHorizontal";

import FilterOptions from "../constants/car-specs";

import {
  getCarMakes,
  getCarModels,
  getCarVariants,
  searchCars,
} from "../utils/api";
import { formatNumber } from "../utils/functions";
import {
  getBodyTypeOptions,
  getColorOptions,
  getFuelTypeOptions,
  getTransmissionOptions,
} from "../utils/constants-helper";
import { useCars } from "../contexts/CarsContext";
import { useQuery } from "../contexts/QueryContext";

const CarsPerPage = 20;

const Pagination = ({ activePage, prev, next, totalCnt }) => {
  return (
    <div className="d-flex align-items-center" style={{ color: "white" }}>
      <div>
        <Icon
          name="angle left"
          size="large"
          className="m-0"
          disabled={activePage === 1}
          onClick={prev}
        />
        <Icon
          name="angle right"
          size="large"
          className="m-0"
          disabled={activePage === Math.ceil(totalCnt / CarsPerPage)}
          onClick={next}
        />
      </div>
      <span>
        Page {activePage} of {formatNumber(Math.ceil(totalCnt / CarsPerPage))}
      </span>
    </div>
  );
};

export default props => {
  const [cookies] = useCookies(["postcode"]);

  const { searchResult, setSearchResult } = useCars();
  const { loading, query, setQuery } = useQuery();

  const [activeItem, setActiveItem] = useState("year");
  const [activePage, setActivePage] = useState(1);
  const [currentPageCars, setCurrentPageCars] = useState(
    searchResult.cars.slice(0, CarsPerPage),
  );
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);
  const [postcode, setPostcode] = useState(cookies.postcode);
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const handleChange = (e, { name, value }) => {
    if (name === "postcode") {
      setPostcode(value.toUpperCase());
    } else {
      setQuery({ ...query, [name]: value });
    }
  };

  const handleEnter = e => {
    if (e.key === "Enter") {
      setQuery({ ...query, postcode: postcode });
    }
  };

  useEffect(() => setActivePage(1), [query]);

  useEffect(() => {
    let startIdx = (activePage - 1) * CarsPerPage;
    let endIdx = activePage * CarsPerPage;
    let temp = [...searchResult.cars.slice(startIdx, endIdx)];
    setCurrentPageCars(temp);
  }, [searchResult]);

  useEffect(() => {
    getCarMakes().then(makes => setMakes(makes));
  }, []);

  useEffect(() => {
    if (query.make !== "")
      getCarModels(query.make).then(models => setModels(models));
    else setModels([]);
  }, [query.make]);

  useEffect(() => {
    if (query.model !== "")
      getCarVariants(query.model).then(variants => setVariants(variants));
    else setVariants([]);
  }, [query.model]);

  const toggleForm = () => {
    setShowSearchFilters(!showSearchFilters);
  };

  const prevPage = () => {
    if (activePage % 5 === 1) {
      let wholeQuery = query;
      wholeQuery.page = activePage / 5 - 1;

      searchCars(wholeQuery)
        .then(cars => {
          setSearchResult({ ...searchResult, cars });
          setCurrentPageCars(cars.slice(CarsPerPage * 4, CarsPerPage * 5));
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setCurrentPageCars(
        searchResult.cars.slice(
          CarsPerPage * ((activePage % 5) - 2),
          CarsPerPage * ((activePage % 5) - 1),
        ),
      );
    }
    setActivePage(activePage - 1);
  };

  const nextPage = () => {
    if (activePage % 5 === 0) {
      let wholeQuery = query;
      wholeQuery.page = activePage / 5;

      searchCars(wholeQuery)
        .then(cars => {
          setSearchResult({ ...searchResult, cars });
          setCurrentPageCars(cars.slice(0, CarsPerPage));
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setCurrentPageCars(
        searchResult.cars.slice(
          CarsPerPage * (activePage % 5),
          CarsPerPage * ((activePage % 5) + 1),
        ),
      );
    }
    setActivePage(activePage + 1);
  };

  const sortbyPriceLowest = (a, b) => {
    return a.price - b.price;
  };

  const sortbyPriceHighest = (a, b) => {
    return b.price - a.price;
  };

  const sortbyPriceRelevance = (a, b) => {
    if (a.headline < b.headline) {
      return -1;
    }
    if (a.headline > b.headline) {
      return 1;
    }
    return 0;
  };

  const sort = (e, { value }) => {
    let cars = [...searchResult.cars];
    switch (value) {
      case "pricel":
        cars = cars.sort(sortbyPriceLowest);
        break;
      case "priceh":
        cars = cars.sort(sortbyPriceHighest);
        break;
      case "relevance":
        cars = cars.sort(sortbyPriceRelevance);
        break;
      default:
        break;
    }

    setActivePage(1);
    setSearchResult({ ...searchResult, cars: cars });
  };

  const resetQuery = () => {
    setActiveItem("year");

    setQuery({
      postcode: cookies.postcode,
      distance: 0,
      make: "",
      model: "",
      variant: "",
      minPrice: 0,
      maxPrice: 0,
      mileage: 0,
      keyword: "",
      transmission: [],
      fuelType: [],
      bodyType: [],
      color: [],
      sellerType: "",
      doorCnt: [],
    });
  };

  return (
    <Container className="pt-2 mt-2 mb-4">
      <Dimmer active={loading} inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>

      <Grid>
        <Grid.Column computer={4} tablet={6} mobile={16}>
          <div className="search-filter__container">
            <h3>
              <Icon name="search" size="large" /> Searched:{" "}
              {formatNumber(searchResult.totalCnt)} cars
            </h3>
            <div className="actions">
              <div>
                <Icon name="heart" /> Save search
              </div>
              <div onClick={resetQuery} className="reset-filter">
                <Icon name="refresh" /> Reset
              </div>
            </div>
            <div className="title-div">
              <h5 className="title">Quick Search select</h5>
              <div onClick={toggleForm}>
                {showSearchFilters ? (
                  <i aria-hidden="true" class="angle up large icon"></i>
                ) : (
                  <i aria-hidden="true" class="angle down large icon"></i>
                )}
              </div>
            </div>
            <div
              className={
                !showSearchFilters
                  ? "filter-form-inactive"
                  : "filter-form-active"
              }
            >
              <Form size={"small"}>
                <Form.Input
                  fluid
                  className="mt-1 mx-2"
                  placeholder="Post code"
                  name="postcode"
                  value={postcode}
                  onChange={handleChange}
                  onKeyDown={handleEnter}
                />
                <Form.Select
                  className="mt-1 mx-2"
                  fluid
                  options={FilterOptions.distances}
                  placeholder="Distance"
                  name="distance"
                  value={query.distance}
                  onChange={handleChange}
                />
                <SearchFilter
                  title="Make"
                  type="select"
                  active={activeItem}
                  onClick={() => setActiveItem("Make")}
                  options={makes}
                  name="make"
                />
                <SearchFilter
                  title="Model"
                  type="select"
                  active={activeItem}
                  onClick={() => setActiveItem("Model")}
                  options={models}
                  name="model"
                />
                <SearchFilter
                  title="Model variant"
                  type="select"
                  active={activeItem}
                  options={variants}
                  onClick={() => setActiveItem("Model-variant")}
                  name="variant"
                />
                <SearchFilter
                  title="Price"
                  type="range"
                  active={activeItem}
                  onClick={() => setActiveItem("Price")}
                  name="Price"
                  options={FilterOptions.price}
                />
                <SearchFilter
                  title="Mileage"
                  type="select"
                  options={FilterOptions.mileage}
                  active={activeItem}
                  onClick={() => setActiveItem("Mileage")}
                  name="mileage"
                />
                <SearchFilter
                  title="Transmission"
                  type="popup"
                  options={getTransmissionOptions()}
                  active={activeItem}
                  onClick={() => setActiveItem("Transmission")}
                  name="transmission"
                />
                <SearchFilter
                  title="Fuel type"
                  type="popup"
                  options={getFuelTypeOptions()}
                  active={activeItem}
                  onClick={() => setActiveItem("Fuel type")}
                  name="fuelType"
                />
                <SearchFilter
                  title="Body type"
                  type="popup"
                  options={getBodyTypeOptions()}
                  active={activeItem}
                  onClick={() => setActiveItem("Body type")}
                  name="bodyType"
                />
                <SearchFilter
                  title="Doors"
                  type="popup"
                  options={FilterOptions.doors}
                  active={activeItem}
                  onClick={() => setActiveItem("Doors")}
                  name="doorCnt"
                />
                <SearchFilter
                  title="Colour"
                  type="popup"
                  options={getColorOptions()}
                  active={activeItem}
                  onClick={() => setActiveItem("Colour")}
                  name="color"
                />
                <SearchFilter
                  title="Private &amp; Trade"
                  type="select"
                  options={FilterOptions.advertType}
                  active={activeItem}
                  onClick={() => setActiveItem("Private &amp; Trade")}
                  name="sellerType"
                />

                <Form.Input
                  fluid
                  className="mt-1 mx-2"
                  placeholder="Keywords e.g. sunroof, sat nav..."
                  name="keyword"
                  value={query.keyword}
                  onChange={handleChange}
                />
              </Form>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={12}
          tablet={10}
          mobile={16}
          className="pt-0 mt-2"
        >
          <div className="listing--controlls mb-3">
            <Pagination
              activePage={activePage}
              prev={prevPage}
              next={nextPage}
              totalCnt={searchResult.totalCnt}
            />

            <Form.Select
              options={FilterOptions.sortOptions}
              placeholder="Sort by"
              label="Sort by : "
              defaultValue="relevance"
              onChange={sort}
            />
          </div>
          {currentPageCars.map((v, idx) => (
            <CarHorizontal key={`carh-${activePage}-${idx}`} detail={v} />
          ))}
        </Grid.Column>
        <Grid.Column width={16}>
          <div className="listing--controlls justify-content-end p-2 pr-3 mb-3">
            <Pagination
              activePage={activePage}
              prev={prevPage}
              next={nextPage}
              totalCnt={searchResult.totalCnt}
            />
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};
