import React from "react";
import { PayPalButton } from "react-paypal-button-v2";

export default ({ price, disabled, onSuccess }) => {
  return (
    <div
      style={
        disabled
          ? { cursor: "not-allowed", ...styles.container }
          : styles.container
      }
    >
      <div style={disabled ? { pointerEvents: "none" } : {}}>
        <PayPalButton
          amount={price}
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            vault: true,
            disableFunding: "card,credit",
            currency: "GBP",
          }}
          onSuccess={onSuccess}
          onError={err => {
            console.log(err);
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: 200,
    width: 200,
  },
};
