import React, { useEffect, useState } from "react";
import { Form, Button, Header } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { getCarMakes, getCarModels, getCarVariants } from "../../utils/api";
import {
  getBodyTypeOptions,
  getColorOptions,
  getFuelTypeOptions,
  getTransmissionOptions,
} from "../../utils/constants-helper";

import CarSpecs from "../../constants/car-specs";

export default ({ goTo }) => {
  const { newCarDetail, handleFieldUpdate } = useNewCarDetail();

  const [validationErr, setValidationErr] = useState({});
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    getCarMakes(true, false).then(makes => setMakes(makes));
  }, []);

  useEffect(() => {
    if (!!newCarDetail.make)
      getCarModels(newCarDetail.make, false).then(models => setModels(models));
  }, [newCarDetail.make]);

  useEffect(() => {
    if (!!newCarDetail.model)
      getCarVariants(newCarDetail.model, false).then(variants =>
        setVariants(variants),
      );
  }, [newCarDetail.model]);

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {};

    if (!newCarDetail.make) {
      errors.make = "Please select the make";
      isValid = false;
    }

    if (!newCarDetail.model) {
      errors.model = "Please select the model";
      isValid = false;
    }

    if (!newCarDetail.bodyType) {
      errors.bodyType = "Please select the body type";
      isValid = false;
    }

    if (!newCarDetail.fuelType) {
      errors.fuelType = "Please select the fuel type";
      isValid = false;
    }

    if (!newCarDetail.transmission) {
      errors.transmission = "Please select the transmission";
      isValid = false;
    }

    if (!newCarDetail.color) {
      errors.color = "Please select the colour";
      isValid = false;
    }

    if (!newCarDetail.doorCnt) {
      errors.doorCnt = "Please select the number of doors";
      isValid = false;
    }

    if (!newCarDetail.engineSize) {
      errors.engineSize = "Please enter the engine size";
      isValid = false;
    }

    if (!newCarDetail.year) {
      errors.year = "Please enter the car manufactured year";
      isValid = false;
    }

    if (newCarDetail.year.length !== 4) {
      errors.year = "Please enter the correct year";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleSubmit = () => {
    console.log(newCarDetail);
    if (checkValidationandShowErr()) goTo(1);
  };

  return (
    <>
      <Header as="h4">Enter your vehicle details</Header>
      <Form onSubmit={handleSubmit}>
        <Form.Select
          inline
          search
          label="* Make"
          placeholder="Make"
          options={makes}
          error={validationErr.make}
          onChange={(e, { value }) => handleFieldUpdate("make", value)}
          value={newCarDetail.make}
        />
        <Form.Select
          inline
          search
          label="* Model"
          placeholder="Model"
          options={models}
          error={validationErr.model}
          onChange={(e, { value }) => handleFieldUpdate("model", value)}
          value={newCarDetail.model}
        />
        <Form.Select
          inline
          search
          label="Variant"
          placeholder="Variant"
          options={variants}
          error={validationErr.variant}
          onChange={(e, { value }) => handleFieldUpdate("variant", value)}
          value={newCarDetail.variant}
        />
        <Form.Select
          inline
          search
          label="* Body Type"
          placeholder="Body Type"
          options={getBodyTypeOptions(false)}
          error={validationErr.bodyType}
          onChange={(e, { value }) => handleFieldUpdate("bodyType", value)}
          value={newCarDetail.bodyType}
        />
        <Form.Select
          inline
          search
          label="* Transmission"
          placeholder="Transmission"
          options={getTransmissionOptions(false)}
          error={validationErr.transmission}
          onChange={(e, { value }) => handleFieldUpdate("transmission", value)}
          value={newCarDetail.transmission}
        />
        <Form.Select
          inline
          search
          label="* Fuel Type"
          placeholder="Fuel Type"
          options={getFuelTypeOptions(false)}
          error={validationErr.fuelType}
          onChange={(e, { value }) => handleFieldUpdate("fuelType", value)}
          value={newCarDetail.fuelType}
        />
        <Form.Select
          inline
          search
          label="* Color"
          placeholder="Color"
          options={getColorOptions(false)}
          error={validationErr.color}
          onChange={(e, { value }) => handleFieldUpdate("color", value)}
          value={newCarDetail.color}
        />
        <Form.Select
          inline
          search
          label="* Number of Doors"
          placeholder="Number of doors"
          options={CarSpecs.doors}
          error={validationErr.doorCnt}
          onChange={(e, { value }) => handleFieldUpdate("doorCnt", value)}
          value={newCarDetail.doorCnt}
        />
        <Form.Input
          inline
          label="* Engine Size"
          type="number"
          placeholder="Engine size"
          pattern="[0-9]*"
          value={newCarDetail.engineSize}
          onChange={(e, { value }) => {
            handleFieldUpdate("engineSize", value);
          }}
          error={validationErr.engineSize}
        />
        <Form.Input
          inline
          label="* Manufactured Year"
          type="number"
          placeholder="Manufactured year"
          value={newCarDetail.year}
          onChange={(e, { value }) => {
            handleFieldUpdate("year", value);
          }}
          error={validationErr.year}
        />
        <Form.Group inline className="date-input">
          <label>Date of first registration</label>
          <DateInput
            value={newCarDetail.registrationDate}
            dateFormat="YYYY-MM-DD"
            onChange={(e, { value }) => {
              handleFieldUpdate("registrationDate", value);
            }}
          />
        </Form.Group>

        <Button type="back" onClick={() => goTo(-1)}>
          Back
        </Button>
        <br />
        <Button type="submit">Confirm</Button>
      </Form>
    </>
  );
};
