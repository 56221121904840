import React, { useState, useRef } from "react";
import { Loader } from "semantic-ui-react";

import useIntersectionObserver from "../hooks/useIntersectionObserver";

const ImageContainer = ({ src, alt, rounded }) => {
  const addDefaultSrc = ev => {
    ev.target.src = "/unavailable.png";
  };

  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true);
        observerElement.unobserve(ref.current);
      }
    },
  });

  return (
    <div ref={ref} className="w-100">
      {isVisible && (
        <>
          <div style={{ visibility: loading ? "visible" : "hidden" }}>
            <Loader active />
          </div>
          <div style={{ visibility: loading ? "hidden" : "visible" }}>
            <img
              className="w-100"
              style={{ display: "block", borderRadius: rounded ? 4 : 0 }}
              src={src}
              alt={alt}
              onError={addDefaultSrc}
              onLoad={() => setLoading(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ImageContainer;
