import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Listing from "./pages/Listing";
import Detail from "./pages/Detail";
import AdvertiseCar from "./pages/AdvertiseCar";
import Login from "./pages/Login";
import Register from "./pages/Register";
import VerifyEmail from "./pages/VerifyEmail";
import Saved from "./pages/Saved";
import CompareCars from "./pages/CompareCars";
import ComingSoon from "./pages/ComingSoon";
import ContactUs from "./pages/ContactUs";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

function App() {
  return (
    <Router>
      <ToastContainer />

      <ScrollToTop />
      <Navbar />

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sign-in" component={Login} />
        <Route path="/register" component={Register} />
        <Route exact path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/used-cars" component={Listing} />
        <Route path="/detail/:carId" component={Detail} />
        <Route path="/advertise" component={AdvertiseCar} />
        <Route path="/saved-cars" component={Saved} />
        <Route path="/compare" component={CompareCars} />
        <Route path="/contact-us" component={ContactUs} />
        <Route component={ComingSoon} />
      </Switch>

      <Footer />
    </Router>
  );
}

export default App;
