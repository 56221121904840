import React from "react";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import LazyImage from "./LazyImage";
import { formatNumber } from "../utils/functions";
import { saveCar } from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

const CarHorizontal = ({ detail, cars, setCars }) => {
  const { auth } = useAuth();

  const unsave = e => {
    e.stopPropagation();
    e.preventDefault();

    saveCar(detail._id, auth?.token)
      .then(response => {
        let temp = cars.filter(car => car._id !== detail._id);
        setCars(temp);

        toast.success(response.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch(error => {
        toast.error("❌ Something went wrong.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      });
  };

  return (
    <Link
      to={{
        pathname: `/detail/${detail._id}`,
        state: {
          distance: detail.distance,
        },
      }}
    >
      <Grid className="car__vertical3">
        <Grid.Row columns={1}>
          <Grid.Column>
            <LazyImage src={detail.image} rounded />
          </Grid.Column>
          <Grid.Column>
            <Header as="h4" className="title">
              {detail.headline}
            </Header>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Price</p>
              <p>
                <b>£{formatNumber(detail.price)}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Year</p>
              <p>
                <b>{detail.year}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Mileage</p>
              <p>
                <b>{formatNumber(detail.mileage)} miles</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Body Type</p>
              <p>
                <b>{detail.bodyType}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Transmission</p>
              <p>
                <b>{detail.transmission}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Fuel Type</p>
              <p>
                <b>{detail.fuelType}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="car__vertical3--detail">
              <p>Doors</p>
              <p>
                <b>{detail.doorCnt}</b>
              </p>
            </div>
          </Grid.Column>
          <Grid.Column className="text-center mt-2">
            <div className="car__vertical3--detail flex-column">
              <p>
                <b>About Seller</b>
              </p>
              {detail.sellerType === "Private" && <p>Private seller</p>}
              {detail.sellerType !== "Private" && <p>{detail.dealer.name}</p>}
              <div className="d-flex align-items-center mt-2">
                <img
                  src={require("../assets/img/location.svg")}
                  alt=""
                  className="mr-1"
                />
                {detail.distance} miles away
              </div>
            </div>
          </Grid.Column>
          <Grid.Column className="text-center mt-2">
            <Button onClick={unsave}>
              <Icon name="remove" />
              Remove
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Link>
  );
};

export default CarHorizontal;
