import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  List,
  Icon,
  Button,
  Image,
  Rating,
} from "semantic-ui-react";
import ImageGallery from "react-image-gallery";
import { useParams, useHistory } from "react-router-dom";
import Map from "pigeon-maps";
import Marker from "pigeon-marker";

import CarVertical from "../components/CarVertical";
import EmailModal from "../components/Detail/EmailModal";
import ExtraInfoAccordion from "../components/Detail/ExtraInfoAccordion";
import { formatNumber } from "../utils/functions";
import { getCarDetail, getLatestCars } from "../utils/api";

import { useAuth } from "../contexts/AuthContext";

export default props => {
  const history = useHistory();
  const { auth } = useAuth();

  const [car, setCar] = useState({});
  const [activeIndex, setActiveIdx] = useState(-1);
  const { carId } = useParams();
  const [latestCars, setLatestCars] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    getLatestCars().then(latestCars => setLatestCars(latestCars));
  }, []);

  useEffect(() => {
    getCarDetail(carId).then(car => {
      if (props?.location?.state?.distance)
        car = { ...car, distance: props?.location?.state?.distance };
      setCar(car);
    });
  }, [carId, props?.location?.state?.distance]);

  const addDefaultSrc = ev => {
    ev.target.src = "/unavailable.png";
  };

  const backToResult = () => {
    history.goBack();
  };

  const mapTilerProvider = (x, y, z, dpr) => {
    return `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`; //<--- tile provider url, should provide colorful map from openstreet
  };

  return (
    <Container className="pd mt-2 mb-4">
      <div className="pd__back my-2 cursor-pointer" onClick={backToResult}>
        <Icon name="arrow left" className="m-0" /> Back to results
      </div>
      <Grid stackable>
        <Grid.Column width={10}>
          {car?.images?.length && <ImageGallery items={car.images} />}

          <h4 className="pd--heading__secondary mt-1">Description</h4>
          <p className="text-16">{car.description}</p>

          <ExtraInfoAccordion info={car.extraInfo} />

          {/* <div className="pd--meta">
            <div style={{ maxWidth: "60%" }}>
              <h4 className="pd--heading__secondary">
                Carmart BMW 3 Series buying tips
              </h4>
              <p>
                We help you get to the nitty-gritty of what to look out for when
                buying the BMW 3 Series coupe Read more
              </p>
            </div>
            <div>
              <Image
                src="https://m.atcdn.co.uk/a/media/w100h75/f52c7db5d6b641dd8dfdf276d18edd6a.jpg"
                alt=""
                onError={addDefaultSrc}
              />
            </div>
          </div> */}

          {/* <div className="pd--meta">
            <div style={{ maxWidth: "60%" }}>
              <h4 className="pd--heading__secondary">More about this dealer</h4>
              <p>
                Our Commitment to you is to maintain the highest possible
                dealership standards at all times, whether you are searching for
                a new or Approved Used BMW or are an existing owner. If
                professional excellence matters to you, come and talk to the
                local experts.
              </p>
            </div>
            <div>
              <Image
                src="https://dealerlogo.atcdn.co.uk/at2/adbranding/10023180/images/searchlogo.gif"
                alt=""
              />
            </div>
          </div> */}
        </Grid.Column>
        <Grid.Column width={6}>
          {/* <div className="pd--tag">
            CARMART Compare <span className="text-bold">Great Price</span>
          </div> */}

          <div className="mt-1 d-flex align-items-center text-10">
            <img
              src={require("../assets/img/camera_b.svg")}
              alt=""
              className="mr-1"
            />{" "}
            {car?.images?.length} images
            {/* <img
              src={require("../assets/img/video_b.svg")}
              alt=""
              className="ml-2 mr-1"
            />{" "}
            1 video */}
          </div>

          <h2 className="pd--price">£{formatNumber(28567)}</h2>
          <h4 className="pd--heading__secondary mt-1">{car.make}</h4>
          <p className="pd--title__sub">{car.headline}</p>

          <List horizontal>
            <List.Item>{car.color}</List.Item>
            <List.Item>{car.mileage} miles</List.Item>
            <List.Item>{car.bodyType}</List.Item>
            <List.Item>{car.transmission}</List.Item>
            <List.Item>{car.fuelType}</List.Item>
          </List>

          <h4 className="pd--heading__secondary mt-1">Overview</h4>
          {car?.specs?.length > 0 && (
            <List>
              {car?.specs?.map((spec, idx) => (
                <List.Item key={`dp-spec-${idx}`}>
                  <List.Icon name="check circle" />
                  <List.Content>{spec}</List.Content>
                </List.Item>
              ))}
            </List>
          )}

          <List horizontal>
            <List.Item>{car.doorCnt} Doors</List.Item>
            <List.Item>{car.mileage} Miles</List.Item>
            <List.Item>{car.year}</List.Item>
          </List>

          <div className="pd__dealer p-2">
            <h4 className="pd--heading__secondary">{car?.dealer?.name}</h4>
            <div className="mt-2">
              <Image
                src="https://dealerlogo.atcdn.co.uk/at2/adbranding/10023180/images/searchlogo.gif"
                alt=""
              />
            </div>
            <div className="mt-2">
              <a
                href={`http://maps.google.com/?q=${car?.dealer?.location[1]},${car?.dealer?.location[0]}`}
                className="d-flex"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon name="map marker alternate" />
                <p>
                  {car?.dealer?.address.split(",").map(v => (
                    <>
                      {v}
                      <br />
                    </>
                  ))}
                </p>
              </a>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <p style={{ marginLeft: "1.6em" }}>{car?.dealer?.postcode}</p>
              <p>{car?.distance} miles away</p>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <a href={`tel:${car?.dealer?.phone}`} className="d-flex">
                <Icon name="phone" />
                <p>
                  {car?.dealer?.phone.split(",").map(v => (
                    <>
                      {v}
                      <br />
                    </>
                  ))}
                </p>
              </a>
              <div>
                <Rating maxRating={5} defaultRating={5} size="small" disabled />
                <span className="ml-1">
                  {car?.dealer?.rating} Star reviews{" "}
                </span>
              </div>
            </div>
            <div style={{ width: "100%", height: 250 }} className="py-2">
              {car?.dealer?.location[1] && (
                <Map
                  defaultCenter={[
                    car?.dealer?.location[1],
                    car?.dealer?.location[0],
                  ]}
                  defaultZoom={12}
                  provider={mapTilerProvider}
                >
                  <Marker
                    anchor={[
                      car?.dealer?.location[1],
                      car?.dealer?.location[0],
                    ]}
                    payload={1}
                  />
                </Map>
              )}
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a
                href={`http://${car?.dealer?.baseURL}`}
                className="ui button secondary fluid"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Dealer's Website
              </a>
            </div>
            {!!car?.dealer?.email && (
              <Button onClick={() => setShowEmailModal(true)}>
                Email Seller
              </Button>
            )}
            {!!car?.dealer?.email && (
              <EmailModal
                open={showEmailModal}
                dealerEmail={car?.dealer?.email}
                price={car.price}
                make={car.make + " " + car.model}
                onClose={() => setShowEmailModal(false)}
              />
            )}
            {auth?.isAdmin && (
              <a
                className="ui button secondary fluid mt-2"
                href={`${process.env.REACT_APP_ADMIN_BASE_URL}/edit-car/${carId}?token=${auth.token}`}
              >
                Edit this car
              </a>
            )}
          </div>
        </Grid.Column>
      </Grid>
      <Grid stackable columns={4}>
        {latestCars.map((car, idx) => (
          <Grid.Column
            computer={4}
            tablet={8}
            mobile={16}
            key={`latest-car-${idx}`}
          >
            <CarVertical detail={car} />
          </Grid.Column>
        ))}
      </Grid>

      {/* <Grid stackable columns={3}>
        <Grid.Column>
          <CarVertical2 />
        </Grid.Column>
        <Grid.Column>
          <CarVertical2 />
        </Grid.Column>
        <Grid.Column>
          <CarVertical2 />
        </Grid.Column>
      </Grid> */}
    </Container>
  );
};
