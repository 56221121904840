import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Form,
  Button,
  Image,
  Header,
  Card,
} from "semantic-ui-react";
import { useCookies } from "react-cookie";

import CarVertical from "../components/CarVertical";
import { getCarMakes, getCarModels, getLatestCars } from "../utils/api";
import FilterOptions from "../constants/car-specs";
import { useQuery } from "../contexts/QueryContext";

const Blog = () => (
  <Card className="blog w-100">
    <Header as="h4">Compare</Header>
    <Image
      src="https://m.atcdn.co.uk/a/media/w800h600/4fdb4a3ee8114b8ca8c51e21be7ca5b1.jpg"
      wrapped
      ui={false}
    />
    <Card.Content>
      <Card.Header>
        Test the marketplace using the Carmart compare tools
      </Card.Header>
      <Card.Description>
        Carmart App makes it really easy to value any car by scanning the
        registration number of any car whilst you’re out and about. Valuations
        have never been easier!
      </Card.Description>
    </Card.Content>
  </Card>
);

export default ({ history }) => {
  const [cookies] = useCookies(["postcode"]);
  const { query, setQuery } = useQuery();

  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [searching, setSearching] = useState(false);
  const [formData, setFormData] = useState({
    postcode: cookies.postcode || "",
    distance: 0,
    make: "",
    model: "",
    minPrice: 0,
    maxPrice: 0,
  });
  const [latestCars, setLatestCars] = useState([]);
  const [validationErr, setValidationErr] = useState({
    postcode: false,
  });

  useEffect(() => {
    getCarMakes().then((makes) => setMakes(makes));
    getLatestCars().then((latestCars) => setLatestCars(latestCars));
  }, []);

  useEffect(() => {
    if (formData.make !== "")
      getCarModels(formData.make).then((models) => setModels(models));
  }, [formData.make]);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {
      postcode: false,
    };

    if (formData.postcode === "") {
      errors.postcode = "Please input the postcode";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const search = () => {
    if (searching) return;

    if (checkValidationandShowErr()) {
      formData.postcode = formData.postcode.toUpperCase();
      setQuery({ ...query, ...formData });
      setSearching(true);

      setTimeout(() => {
        setSearching(false);
        history.push("/used-cars");
      }, 500);
    }
  };

  return (
    <div className="mb-4">
      <div className="head-bg">
        <Image src={require("../assets/img/head-bg.png")} alt="" fluid />
        <Container className="section--heading">
          <Grid>
            <Grid.Column
              mobile={16}
              computer={6}
              className="search--form"
            >
              <Header as="h3">Compare the entire Car Market now</Header>

              <Form loading={searching} onSubmit={search}>
                <Form.Button fluid secondary>
                  Help me find a car
                </Form.Button>

                <Form.Group widths="equal">
                  <Form.Input
                    className="postcode"
                    placeholder="Postcode"
                    name="postcode"
                    value={formData.postcode.toUpperCase()}
                    onChange={handleChange}
                    error={validationErr.postcode}
                  />
                  <Form.Select
                    search
                    placeholder="Distance"
                    name="distance"
                    value={formData.distance}
                    onChange={handleChange}
                    options={FilterOptions.distances}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    search
                    placeholder="Make"
                    name="make"
                    value={formData.make}
                    onChange={handleChange}
                    options={makes}
                  />
                  <Form.Select
                    placeholder="Model"
                    name="model"
                    value={formData.model}
                    onChange={handleChange}
                    options={models}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    placeholder="Min Price"
                    name="minPrice"
                    onChange={handleChange}
                  />
                  <Form.Input
                    placeholder="Max Price"
                    name="maxPrice"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button type="submit" fluid primary>
                  Search
                </Button>
              </Form>
            </Grid.Column>
            <Grid.Column width={1} only="computer"></Grid.Column>
            <Grid.Column
              mobile={16}
              computer={8}
              className="header--text"
            >
              <Header as="h1">
                Find your perfect car by comparing over 460,902 Cars in an
                instant.
              </Header>
              <p>
                Carmart makes it easy to compare the entire used and new car
                marketplace in 3 simple steps. Just choose the type of car,
                number of seats and price range and press ‘GO’. Alternatively,
                if you know which car you want then use the standard search for
                the right car for you.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <Container className="mt-3">
        <h2 className="heading-two">The latest cars added to Carmart</h2>
        <Grid stackable columns={4}>
          {latestCars.map((car, idx) => (
            <Grid.Column key={`latest-car-${idx}`}>
              <CarVertical detail={car} />
            </Grid.Column>
          ))}
        </Grid>
      </Container>
      <Container className="app-bg mt-3">
        <Image src={require("../assets/img/app-bg.png")} alt="" fluid />
        <div className="app--header">
          <Header as="h1">
            Find out what any car is worth with the{" "}
            <span className="text-blue">SmartCarmart</span> app, it’s instant!
          </Header>
          <p>
            <span className="text-bold">Carmart App</span> makes it really easy
            to value any car by scanning the registration number of any car
            whilst you’re out and about. Valuations have never been easier!
          </p>
        </div>
      </Container>
    </div>
  );
};
