import React from "react";
import { Container, Header } from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";

export default () => {
  const { newCarDetail } = useNewCarDetail();

  return (
    <div className="step7">
      <div className="p-5">
        <Header as="h3">Thank you!</Header>
        <p>You advert will be live soon</p>
      </div>
      <Container className="mt-5">
        <div className="shadow">
          <p>
            <b>
              An receipt has been emailed to {newCarDetail.advertiser.email}
            </b>
          </p>
          <p>
            <b>
              You will received an email soon as your advert has been checked
              and made live
            </b>
            <br />
            <b>Your advert will expire on 3 weeks later</b>
          </p>
          <p>
            <b>Please log in and remove the advert when you sell the car</b>
          </p>
        </div>
      </Container>
    </div>
  );
};
