import React from "react";
import { Icon } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth } from "../contexts/AuthContext";
import { useCars } from "../contexts/CarsContext";
import { saveCar } from "../utils/api";

import "../scss/save-car.scss";

export default ({ id, distance, saved }) => {
  const { auth } = useAuth();
  const { searchResult, setSearchResult } = useCars();

  const showSavedIcon = saved ? "show" : "hidden";
  const btnText = saved ? "Unsave" : "Save";

  const handleSaveCar = (e) => {
    e.stopPropagation();
    e.preventDefault();

    saveCar(id, distance, auth?.token)
      .then((response) => {
        let temp = [...searchResult.cars];
        let curCarIdx = temp.findIndex((car) => car._id === id);
        temp[curCarIdx].saved = !temp[curCarIdx].saved;
        setSearchResult({ ...searchResult, cars: temp });

        toast.success(response.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("❌ Please sign in to save a car.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        } else {
          toast.error("❌ Something went wrong.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
        }
      });
  };

  return (
    <div className="save-car">
      <div className={`save-car__saved ${showSavedIcon}`}>
        <Icon name="heart" />
      </div>

      <button className="save-car__btn" onClick={handleSaveCar}>
        <Icon name="heart outline" />
        {btnText}
      </button>
    </div>
  );
};
