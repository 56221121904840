import React, { useState } from "react";
import { Button, Header, Form } from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import CarSpecs from "../../constants/car-specs";

export default ({ goTo }) => {
  const { newCarDetail, handleFieldUpdate } = useNewCarDetail();

  const handleChkBoxChange = value => (e, { checked }) => {
    let specs = [...newCarDetail.specs];
    if (checked) {
      specs.push(value);
    } else {
      const index = specs.indexOf(value);
      if (index !== -1) {
        specs.splice(index, 1);
      }
    }

    handleFieldUpdate("specs", specs);
  };

  const handleSubmit = () => {
    goTo(1);
  };

  return (
    <>
      <Header as="h4">Select the specification</Header>
      <Form className="ml-3" onSubmit={handleSubmit}>
        {CarSpecs.specifications.map(feature => {
          return (
            <>
              <Header as="h6">{feature.name}</Header>
              <div className="ml-3">
                {feature.specs.map(spec => {
                  console.log();
                  return (
                    <Form.Checkbox
                      label={spec.name}
                      onChange={handleChkBoxChange(spec.name)}
                      checked={newCarDetail.specs.includes(spec.name)}
                    />
                  );
                })}
              </div>
            </>
          );
        })}

        <Button type="back" className="mt-5" onClick={() => goTo(-1)}>
          Back
        </Button>
        <br />
        <Button type="submit">Confirm</Button>
      </Form>
    </>
  );
};
