import React, { useState, useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";
import { verifyEmail } from "../utils/api";

export default (props) => {
  const { auth, setAuthCtx } = useAuth();
  if (auth.token) return <Redirect to="/saved" />;

  const [text, setText] = useState("Please wait, while checking the token.");
  useEffect(() => {
    verifyEmail(props.match.params.token)
      .then((data) => {
        if (data.status === "success") {
          setText("The Email is verified. Redirecting to Home...");
          setAuthCtx(data);
          setTimeout(() => {
            props.history.push("/saved");
          }, 3000);
        } else {
          return Promise.reject("Token Invalid");
        }
      })
      .catch((err) => {
        console.log(err);
        setText("The token is invalid. Redirecting to Login...");
        setTimeout(() => {
          props.history.push("/sign-in");
        }, 3000);
      });
  }, []);
  return (
    <Dimmer active inverted>
      <Loader size="large">Loading</Loader>
      <p>{text}</p>
    </Dimmer>
  );
};
