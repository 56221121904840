import React from "react";
import { Image, List } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";

import { formatNumber } from "../utils/functions";

export default ({ detail }) => {
  const addDefaultSrc = ev => {
    ev.target.src = "/unavailable.png";
  };

  return (
    <Link to={`/detail/${detail._id}`}>
      <div className="car__vertical">
        <Image
          src={detail.images[0]}
          alt=""
          onError={addDefaultSrc}
          className="w-100"
        />
        <div className="price--text">
          <p>Price</p>
          <p>CM Compare Guide Price</p>
        </div>
        <div className="price--number">
          <p>£{formatNumber(detail.price)}</p>
          <p>£{formatNumber(21098)}</p>
        </div>
        <h4 className="title text-16" style={{ minHeight: 40 }}>
          {detail.headline}
        </h4>
        <div className="px-2 my-1 text-16">
          <List horizontal>
            <List.Item>{detail.bodyType}</List.Item>
            <List.Item>{detail.transmission}</List.Item>
            <List.Item>{detail.fuelType}</List.Item>
          </List>
        </div>
        {/* <div className="action" onClick={handleClick}>
        Start Comparing now
      </div> */}
      </div>
    </Link>
  );
};
