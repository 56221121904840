import React from "react";
import { Grid, List } from "semantic-ui-react";
import { Link } from "react-router-dom";

import LazyImage from "./LazyImage";
import SaveCar from "./SaveCar";
import { formatNumber } from "../utils/functions";

const CarHorizontal = ({ detail }) => {
  return (
    <Link
      to={{
        pathname: `/detail/${detail._id}`,
        state: {
          distance: detail.distance,
        },
      }}
    >
      <Grid className="car__horizontal">
        <Grid.Row columns={2} className="header">
          <Grid.Column className="d-flex align-items-center text-10">
            <img
              src={require("../assets/img/camera.svg")}
              alt=""
              style={{ width: 20 }}
            />{" "}
            {detail.images.length} images
            {/* <img
            src={require("../assets/img/video.svg")}
            alt=""
            className="ml-2"
            style={{ width: 22 }}
          />{" "}
          1 video */}
          </Grid.Column>
          {/* <Grid.Column className="d-flex justify-content-end pr-0">
            <p className="tag text-10">Compare CAMART GREAT Price</p>
          </Grid.Column> */}
        </Grid.Row>
        <Grid.Column computer={6} tablet={12} mobile={16}>
          <div className='fixed__Height'>
            <LazyImage src={detail.images[0]} />
            <SaveCar
              id={detail._id}
              distance={detail.distance}
              saved={detail.saved}
            />
          </div>
        </Grid.Column>
        <Grid.Column computer={2} tablet={4} mobile={16} className="more_cars">
          {detail.images.slice(0, 3).map((img, idx) => (
            <LazyImage src={img} key={`carh-img-${idx}`} />
          ))}
        </Grid.Column>
        <Grid.Column
          computer={8}
          tablet={16}
          mobile={16}
          className="d-flex flex-column justify-content-between"
        >
          <div className="p-2">
            <div>
              <h4 className="price">£{formatNumber(detail.price)}</h4>
              <h4 className="title">{detail.headline}</h4>

              <List horizontal style={{ color: "#0B3464" }}>
                <List.Item>{detail.year}</List.Item>
                <List.Item>{detail.mileage} miles</List.Item>
                <List.Item>{detail.bodyType}</List.Item>
                <List.Item>{detail.transmission}</List.Item>
                <List.Item>{detail.fuelType}</List.Item>
              </List>

              <p className="description mt-2">{detail.description}</p>
            </div>
          </div>
          <div className="d-flex text-10">
            <div className="d-flex align-items-center">
              <img
                src={require("../assets/img/location.svg")}
                alt=""
                className="ml-2 mr-1"
              />
              {detail.distance} miles away
            </div>
            <div className="d-flex align-items-center mr-2">
              <img
                src={require("../assets/img/car.svg")}
                alt=""
                className="ml-2 mr-1"
              />
              {detail.sellerType} seller
            </div>
            {/* <Rating maxRating={5} defaultRating={5} size="small" disabled />
            <span className="ml-1">{5} Star reviews </span> */}
          </div>
          <div>
            {/* <Image
            src={detail.company.logo}
            alt="logo"
            style={{ margin: "0 1em 1em auto" }}
          /> */}
          </div>
        </Grid.Column>
      </Grid>
    </Link>
  );
};

export default CarHorizontal;
