import axios from "axios";

const createAxios = token => {
  const config = {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  };
  const instance = axios.create(config);

  return instance;
};

export const POST = (url, data, token = null) =>
  createAxios(token).post(url, data);
export const GET = (url, params, token = null) =>
  createAxios(token).get(url, { params });
export const PUT = (url, data, token = null) =>
  createAxios(token).put(url, data);

export const getCarMakes = async (forSelect = true, addAny = true) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/make`,
  );
  if (!forSelect) return data.makes;

  let makes = [];
  if (addAny) makes.push({ key: "make-any", value: "", text: "Any" });

  for (let make of data.makes) {
    makes.push({
      key: `make-${make.name}`,
      value: make.name,
      text: make.name,
    });
  }

  return makes;
};

export const getCarModels = async (make, addAny = true) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/model`,
    {
      make,
    },
  );

  let models = [];
  if (addAny) models.push({ key: "model-any", value: "", text: "Any" });

  for (let model of data.models) {
    models.push({
      key: `model-${model.name}`,
      value: model.name,
      text: model.name,
    });
  }
  return models;
};

export const getCarVariants = async (model, addAny = true) => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/variant`,
    {
      model,
    },
  );

  let variants = [];
  if (addAny) variants.push({ key: "variant-Any", text: "Any", value: "" });
  for (let variant of data.variants) {
    variants.push({
      key: `variant-${variant.name}`,
      value: variant.name,
      text: variant.name,
    });
  }
  return variants;
};

export const searchCars = async (searchData, userId) => {
  searchData.userId = userId;
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/search`,
    searchData,
  );

  return data;
};

export const searchCarsCount = async searchData => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/search-cnt`,
    searchData,
  );

  return data;
};

export const getJarvisData = async vrm => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/jarvis`,
    {
      vrm,
    },
  );

  return data;
};

export const uploadImages = async images => {
  const config = {
    "content-type": "multipart/form-data",
  };
  const instance = axios.create(config);
  const formData = new FormData();

  for (let i = 0; i < images.length; i++) {
    formData.append(`images`, images[i]);
  }

  let { data } = await instance.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/upload-images`,
    formData,
  );

  return data;
};

export const addNewCar = async car => {
  let carValue = { ...car };
  carValue.mileage = parseInt(carValue.mileage);
  carValue.price = parseInt(carValue.price);
  carValue.imageFiles = undefined;

  let { data } = await PUT(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/`,
    carValue,
  );

  return data;
};

export const getCarDetail = async id => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/${id}`,
  );

  return data;
};

export const login = async formData => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
    formData,
  );

  return data;
};

export const register = async formData => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/signup`,
    formData,
  );

  return data;
};

export const resendEmail = async email => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/resend`,
    {
      email,
    },
  );

  return data;
};

export const verifyEmail = async token => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/verify`,
    {
      token,
    },
  );

  return data;
};
/*
export const forgotPwd = async (email) => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/forgot`,
    {
      email,
    }
  );

  return data;
};
*/

export const getLatestCars = async () => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/cars/latest`,
  );

  return data;
};

export const saveCar = async (carId, distance, token) => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/user/save-car`,
    { carId, distance },
    token,
  );
  return data;
};

export const getSavedCars = async token => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/user/saved-cars`,
    {},
    token,
  );
  return data;
};

export const getComparingDetails = async (ids, token) => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/user/comparing-cars`,
    { ids },
    token,
  );
  return data;
};

export const sendEmail2Seller = async formData => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/extra/email-dealer`,
    formData,
  );
  return data;
};

export const sendContactEmail = async (formData, token) => {
  let { data } = await POST(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/extra/contact-us`,
    formData,
    token,
  );
  return data;
};

export const getSiteConfig = async () => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/extra/config`,
  );
  return data;
};

export const getPromo = async code => {
  let { data } = await GET(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/extra/promo?code=${code}`,
  );
  return data;
};
