import React, { createContext, useContext, useState } from "react";

import { getNewCarContextInitialValue } from "../utils/constants-helper";

export const newCarContext = createContext({});

const NewCarProvider = ({ children }) => {
  const [newCarDetail, setNewCarDetail] = useState(
    getNewCarContextInitialValue,
  );

  const handleFieldUpdate = (field, value) => {
    if (field.includes("advertiser.")) {
      let fieldName = field.split("advertiser.");
      fieldName = fieldName[fieldName.length - 1];

      const advertiser = { ...newCarDetail.advertiser, [fieldName]: value };
      setNewCarDetail({ ...newCarDetail, advertiser: advertiser });
    } else {
      if (field === "make") {
        setNewCarDetail({
          ...newCarDetail,
          [field]: value,
          model: "",
          variant: "",
        });
      } else if (field === "model") {
        setNewCarDetail({ ...newCarDetail, [field]: value, variant: "" });
      } else setNewCarDetail({ ...newCarDetail, [field]: value });
    }
  };

  return (
    <newCarContext.Provider
      value={{
        newCarDetail,
        handleFieldUpdate,
        setNewCarDetail,
      }}
    >
      {children}
    </newCarContext.Provider>
  );
};

export const useNewCarDetail = () => useContext(newCarContext);
export default NewCarProvider;
