import React from "react";
import { Button, Header } from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";

export default ({ goTo }) => {
  const { newCarDetail } = useNewCarDetail();

  return (
    <>
      <Header as="h4">Confirm your vehicle</Header>
      <b>{newCarDetail.make + " " + newCarDetail.model}</b>
      <p>{newCarDetail.variant}</p>
      <hr />
      <div className="d-flex mt-3">
        <label className="text-left">
          <b>Fuel Type</b>
        </label>
        <p>{newCarDetail.fuelType}</p>
      </div>
      <div className="d-flex mt-1">
        <label className="text-left">
          <b>Engine Size</b>
        </label>
        <p>{newCarDetail.engineSize}</p>
      </div>
      <div className="d-flex mt-1">
        <label className="text-left">
          <b>Body Type</b>
        </label>
        <p>{newCarDetail.bodyType}</p>
      </div>
      <div className="d-flex mt-1">
        <label className="text-left">
          <b>Colour</b>
        </label>
        <p>{newCarDetail.color}</p>
      </div>
      <div className="d-flex mt-1">
        <label className="text-left">
          <b>Transmission</b>
        </label>
        <p>{newCarDetail.transmission}</p>
      </div>
      <div className="d-flex mt-1 mb-3">
        <label className="text-left">
          <b>Date of first registration</b>
        </label>
        <p>{newCarDetail.registrationDate}</p>
      </div>
      <hr />

      <div className="mt-3">
        <b>Not right?</b>
      </div>
      <p className="mt-1">
        Check your registration or{" "}
        <span
          style={{ color: "blue" }}
          className="cursor-pointer"
          onClick={() => {
            goTo(-1);
          }}
        >
          make changes
        </span>
      </p>
      <Button type="back" onClick={() => goTo(-2)}>
        Back
      </Button>
      <br />
      <Button type="submit" onClick={() => goTo(1)}>
        Confirm
      </Button>
    </>
  );
};
