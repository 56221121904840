import React, { useState } from "react";
import { Container, Grid, Accordion, List, Icon } from "semantic-ui-react";
import ImageGallery from "react-image-gallery";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { formatNumber } from "../../utils/functions";
import CarSpecs from "../../constants/car-specs";

export default ({ goTo }) => {
  const { newCarDetail } = useNewCarDetail();
  const [activeIndex, setActiveIdx] = useState(-1);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIdx(newIndex);
  };

  const images = [];
  for (let i = 0; i < newCarDetail.images.length; i++) {
    if (images[i])
      images.push({
        original: newCarDetail.images[i],
        thumbnail: newCarDetail.images[i],
      });
  }

  const getAccordionItem = (key, value) => {
    return (
      <Grid.Column>
        <li className="dots">
          <span>
            <Icon name="check circle" color="green" />
            {key}
          </span>
          <span>{value}</span>
        </li>
      </Grid.Column>
    );
  };

  return (
    <Container className="pd mt-2 mb-4">
      <div className="pd__back my-2 text-12" onClick={() => goTo(-1)}>
        <Icon name="angle left" size="large" className="m-0" /> Back to Details
      </div>
      <Grid stackable>
        <Grid.Column width={10}>
          {images.length > 0 && <ImageGallery items={images} />}
          <h4 className="pd--heading__secondary mt-1">Description</h4>
          <p className="text-16">{newCarDetail.description}</p>

          <Accordion className="text-16 specs_accordion">
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={handleAccordionClick}
              className="d-flex justify-content-between"
            >
              <div className="d-flex align-items-center">
                <Icon name="settings" className="mr-1" />
                <h4 className="pd--heading__secondary p-0">
                  Full specification
                </h4>
              </div>
              <Icon name={activeIndex === 0 ? "angle up" : "angle down"} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Grid columns={1} className="pr-5">
                {getAccordionItem("Mileage", newCarDetail.mileage)}
                {getAccordionItem("Make", newCarDetail.make)}
                {getAccordionItem("Model", newCarDetail.model)}
                {getAccordionItem("Variant", newCarDetail.variant)}
                {getAccordionItem("Door Count", newCarDetail.doorCnt)}
                {getAccordionItem("Fuel Type", newCarDetail.fuelType)}
                {getAccordionItem("Body Type", newCarDetail.bodyType)}
                {getAccordionItem("Transmission", newCarDetail.transmission)}
                {getAccordionItem("Engine Size", newCarDetail.engineSize)}
                {getAccordionItem("Postcode", newCarDetail.postcode)}
                {getAccordionItem(
                  "Registration Date",
                  newCarDetail.registrationDate,
                )}
                {getAccordionItem(
                  "MOT Expiry Date",
                  newCarDetail.motExpiryDate,
                )}
              </Grid>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={handleAccordionClick}
              className="d-flex justify-content-between"
            >
              <div className="d-flex align-items-center">
                <Icon name="settings" className="mr-1" />
                <h4 className="pd--heading__secondary p-0">User Details</h4>
              </div>
              <Icon name={activeIndex === 1 ? "angle up" : "angle down"} />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Grid columns={1}>
                {getAccordionItem(
                  "Name",
                  `${newCarDetail.advertiser.firstName} ${newCarDetail.advertiser.lastName}`,
                )}
                {getAccordionItem("Email", newCarDetail.advertiser.email)}
                {getAccordionItem("Phone", newCarDetail.advertiser.phone)}
                {getAccordionItem("Address", newCarDetail.advertiser.address)}
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
        <Grid.Column width={6}>
          <div className="pd--tag">CARMART</div>

          <div className="mt-1 d-flex align-items-center text-10">
            <img
              src={require("../../assets/img/camera_b.svg")}
              alt=""
              className="mr-1"
            />{" "}
            {newCarDetail.images.length} images
            {/* <img
                src={require("../../assets/img/video_b.svg")}
                alt=""
                className="ml-2 mr-1"
              />{" "}
              1 video */}
          </div>

          <h2 className="pd--price">£{formatNumber(newCarDetail.price)}</h2>
          <h4 className="pd--heading__secondary">{newCarDetail.headline}</h4>
          <p className="pd--title__sub">
            {newCarDetail.variant + " " + newCarDetail.bodyType}
          </p>

          <h4 className="pd--heading__secondary mt-1">Overview</h4>
          <List>
            {CarSpecs.specifications.map(feature => {
              return (
                <>
                  {feature.specs.map(spec => {
                    if (newCarDetail.specs[spec.idx])
                      return (
                        <List.Item key={`spec-${spec.idx}`}>
                          <List.Icon name="check circle" />
                          <List.Content>{spec.name}</List.Content>
                        </List.Item>
                      );
                    return <></>;
                  })}
                </>
              );
            })}
          </List>
        </Grid.Column>
      </Grid>
    </Container>
  );
};
