import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { useCars } from "./CarsContext";
import { useAuth } from "./AuthContext";
import { searchCars, searchCarsCount } from "../utils/api";

export const queryContext = createContext({});

const QueryProvider = ({ children }) => {
  const { auth } = useAuth();
  const { setSearchResult } = useCars();

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    postcode: "",
    distance: 0,
    make: "",
    model: "",
    variant: "",
    minPrice: 0,
    maxPrice: 0,
    mileage: 0,
    keyword: "",
    transmission: [],
    fuelType: [],
    bodyType: [],
    color: [],
    sellerType: "",
    doorCnt: [],
  });

  const [cookies, setCookie] = useCookies(["postcode"]);
  useEffect(() => {
    setQuery({ ...query, postcode: cookies.postcode });
  }, []);

  useEffect(() => {
    const current = new Date();
    const nextYear = new Date();

    nextYear.setFullYear(current.getFullYear() + 1);
    if (!!query.postcode)
      setCookie("postcode", query.postcode, {
        path: "/",
        expires: nextYear,
      });
  }, [query.postcode]);

  useEffect(() => {
    setLoading(true);
    let totalCnt = 0;

    searchCarsCount(query)
      .then((count) => {
        totalCnt = count;
        return searchCars(query, auth?.id);
      })
      .then((cars) => {
        setSearchResult({ cars, totalCnt });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [query]);

  return (
    <queryContext.Provider value={{ loading, query, setQuery }}>
      {children}
    </queryContext.Provider>
  );
};

export const useQuery = () => useContext(queryContext);
export default QueryProvider;
