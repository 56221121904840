import React, { useState, useEffect } from "react";
import { Container, Header, Grid, Icon } from "semantic-ui-react";
import { Redirect, useHistory } from "react-router-dom";

import CarVertical3 from "../components/CarVertical3";
import { useAuth } from "../contexts/AuthContext";
import { getComparingDetails } from "../utils/api";

const CompareCars = (props) => {
  const history = useHistory();
  const { auth } = useAuth();
  const [comparingCars, setComparingCars] = useState([]);

  useEffect(() => {
    let selectedCars = props.location.state.cars;
    let ids = selectedCars.map((v) => v.id);
    getComparingDetails(ids, auth.token)
      .then((cars) => {
        cars = cars.map((car) => {
          let distance = selectedCars.filter((v) => v.id === car._id)[0]
            .distance;
          return { ...car, distance: distance };
        });
        setComparingCars(cars);
      })
      .catch((err) => console.log(err));
  }, [auth.token, props.location.state.cars]);

  if (!auth.token) {
    return <Redirect to="/sign-in" />;
  }

  const goBack = () => {
    history.push("/saved");
  };

  return (
    <Container className="py-5 position-relative">
      <div
        style={{ position: "absolute", top: 66, cursor: "pointer" }}
        onClick={goBack}
      >
        <Icon name="arrow left" /> Back
      </div>
      <Header as="h1" className="text-center mb-3">
        Comparing {comparingCars.length} cars
      </Header>

      <Grid columns={4}>
        {comparingCars.map((car) => (
          <Grid.Column>
            <CarVertical3
              detail={car}
              cars={comparingCars}
              setCars={setComparingCars}
            />
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  );
};

export default CompareCars;
