import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Input,
  Message,
  Form,
  Icon,
} from "semantic-ui-react";
import { toast } from "react-toastify";

import PayPalButton from "./components/PaypalBtn";
import StripeButton from "./components/StripeBtn";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { addNewCar, getSiteConfig, getPromo } from "../../utils/api";

export default ({ goTo }) => {
  const { newCarDetail } = useNewCarDetail();
  const [originalPrice, setOriginalPrice] = useState(5);
  const [price, setPrice] = useState(5);
  const [promocode, setPromocode] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    getSiteConfig().then(data => {
      setOriginalPrice(data.price);
      setPrice(data.price);
    });
  }, []);

  const applyPromocode = () => {
    if (!promocode) {
      setError({ ...error, promocode: "Please input the promo code" });
      return;
    }

    getPromo(promocode)
      .then(({ type, amount }) => {
        const discountPrice =
          type === "Percent"
            ? (originalPrice * parseFloat(amount)) / 100
            : parseFloat(amount);

        if (originalPrice - discountPrice > 0)
          setPrice(originalPrice - discountPrice);
        else setPrice(0);
        setError({ ...error, promocode: false });
      })
      .catch(() =>
        setError({ ...error, promocode: "Promo code is not valid." }),
      );
  };

  const onPaymentSuccess = () => {
    addNewCar(newCarDetail)
      .then(res => {
        toast.success(res.message);
        goTo(1);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className="step7">
      <div className="p-5">
        <Header as="h3">Payment</Header>
        <p>Please enter your payment details</p>
      </div>
      <Container className="mt-5">
        <Grid columns={2} className="shadow">
          <Grid.Column>
            <Header as="h6">Invoice</Header>
            <b>Contact details</b>
            <p>{`${newCarDetail.advertiser.firstName} ${newCarDetail.advertiser.lastName}`}</p>
            <p>{newCarDetail.advertiser.phone} (protected)</p>
            <p>{newCarDetail.postcode}(not shown in ad)</p>

            <b>Package</b>
            <p>3 Week Advert </p>

            <b>Price</b>
            <p>£ {price.toFixed(2)} </p>
          </Grid.Column>
          <Grid.Column className="promo">
            <Header as="h6">Promo Code?</Header>
            <Input
              placeholder="Promo code here"
              className="w-100"
              onChange={e => setPromocode(e.target.value)}
            />
            {!!error.promocode && (
              <Message negative content={error.promocode} />
            )}

            <br />
            <Button onClick={applyPromocode}>Apply Code</Button>
          </Grid.Column>
        </Grid>
        <div
          className="mt-5 shadow"
          style={{ marginLeft: -14, marginRight: -14 }}
        >
          <Header as="h3">Payment method</Header>
          <Form.Checkbox
            label="I accept Carmart terms and conditions. I understand if the advert is cancelled for any reason no refund will be provided and the advert will be removed if it breached our terms"
            className="my-2"
            checked={agreeTerms}
            onChange={() => setAgreeTerms(!agreeTerms)}
            error={error.terms}
          />
          <div className="ml-3">
            <PayPalButton
              price={price}
              onSuccess={onPaymentSuccess}
              disabled={!agreeTerms}
            />
            <StripeButton
              onSuccess={onPaymentSuccess}
              price={price}
              disabled={!agreeTerms}
            />
          </div>
        </div>
        <div className="pd__back my-2 text-12" onClick={() => goTo(-1)}>
          <Icon name="angle left" size="large" className="m-0" /> Back to
          Details
        </div>
      </Container>
    </div>
  );
};
