import React, { useState } from "react";
import { Form, Button, Header } from "semantic-ui-react";

import { useNewCarDetail } from "../../contexts/NewCarContext";
import { getJarvisData } from "../../utils/api";

import CarSpecs from "../../constants/car-specs";

const getStandardValue = (options, value) => {
  for (let i = 0; i < options.length; i++) {
    let type = options[i].value.toLowerCase();
    if (value.toLowerCase().includes(type)) {
      return options[i].value;
    }
  }
  return value;
};

export default ({ goTo }) => {
  const {
    newCarDetail,
    handleFieldUpdate,
    setNewCarDetail,
  } = useNewCarDetail();

  const [formLoading, setFormLoading] = useState(false);
  const [validationErr, setValidationErr] = useState({
    reg: false,
    mileage: false,
  });

  const checkValidationandShowErr = () => {
    let isValid = true;
    let errors = {
      reg: false,
      mileage: false,
    };
    if (!newCarDetail.reg) {
      errors.reg = "Please input your reg";
      isValid = false;
    }

    if (!newCarDetail.mileage) {
      errors.mileage = "Please input the mileage";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (formLoading) return;

    if (checkValidationandShowErr()) {
      setFormLoading(true);

      getJarvisData(newCarDetail.reg)
        .then(data => {
          console.log(data);

          let {
            make,
            model,
            variant,
            color,
            fuelType,
            bodyType,
            engineSize,
            transmission,
            doorCnt,
            registrationDate,
          } = data;

          // Get standard values
          bodyType = getStandardValue(CarSpecs.bodyType, bodyType);
          fuelType = getStandardValue(CarSpecs.fuelType, fuelType);
          color = getStandardValue(CarSpecs.color, color);
          transmission = getStandardValue(CarSpecs.transmission, transmission);

          setNewCarDetail({
            ...newCarDetail,
            make,
            model,
            variant,
            color,
            fuelType,
            bodyType,
            engineSize,
            transmission,
            doorCnt,
            registrationDate,
          });

          setTimeout(() => {
            if (
              !!make &&
              !!model &&
              !!variant &&
              !!color &&
              !!fuelType &&
              !!bodyType &&
              !!engineSize &&
              !!transmission &&
              !!doorCnt &&
              !!registrationDate
            ) {
              goTo(2);
            } else goTo(1);
          }, 300);
        })
        .catch(err => {
          // Go to CarDetailForm as getting car details from jarvis failed
          goTo(1);
        })
        .finally(() => setFormLoading(false));
    }
  };

  return (
    <Form onSubmit={handleSubmit} loading={formLoading}>
      <Form.Field>
        <Header as="h4">1. Enter Your Reg</Header>
        <Form.Input
          size="big"
          placeholder="Your reg here"
          onChange={e => handleFieldUpdate("reg", e.target.value.toUpperCase())}
          error={validationErr.reg}
          value={newCarDetail.reg}
        />
      </Form.Field>
      <Form.Field>
        <Header as="h4">2. Enter Your Mileage</Header>
        <Form.Input
          size="big"
          placeholder="Your reg mileage"
          onChange={e => handleFieldUpdate("mileage", e.target.value)}
          error={validationErr.mileage}
          pattern="[0-9]*"
          value={newCarDetail.mileage}
        />
      </Form.Field>

      <Button type="submit">Submit</Button>
    </Form>
  );
};
