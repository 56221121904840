import React, { useState } from "react";
import {
  Container,
  Form,
  Header,
  Button,
  Divider,
  Message,
  Loader,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { register, resendEmail } from "../utils/api";
import { isEmailValid } from "../utils/functions";

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    cfrmPwd: "",
  });
  const [formBusy, setFormBusy] = useState(false);
  const [validationErr, setValidationErr] = useState({
    email: false,
    password: false,
    cfrmPwd: false,
  });
  const [disableResend, setDisableResend] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [formState, setFormState] = useState({
    show: false,
    success: true,
    message: "",
  });

  const checkValidationandShowErr = () => {
    let isValid = true;

    let errors = {
      email: false,
      password: false,
      cfrmPwd: false,
    };

    if (formData.email === "") {
      errors.email = "Please input the email";
      isValid = false;
    } else if (!isEmailValid(formData.email)) {
      errors.email = "Please input the valid email";
      isValid = false;
    }

    if (formData.password === "") {
      errors.password = "Please input the password";
      isValid = false;
    }

    if (formData.cfrmPwd === "") {
      errors.cfrmPwd = "Please confirm your password";
      isValid = false;
    } else if (formData.cfrmPwd !== formData.password) {
      console.log(formData.cfrmPwd, formData.password);
      errors.cfrmPwd = "Password doesn't match";
      isValid = false;
    }

    setValidationErr(errors);
    return isValid;
  };

  const handleChange = (e, { name }) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const handleSignup = (e) => {
    if (checkValidationandShowErr()) {
      e.preventDefault();

      if (formBusy) return;

      if (checkValidationandShowErr()) {
        setFormBusy(true);
        register(formData)
          .then((res) => {
            if (res.status === "success") {
              setFormState({
                show: true,
                success: true,
                message: "The verification email is sent",
              });
              setShowResend(true);
            } else {
              setFormState({
                show: true,
                success: false,
                message: res.message,
              });
            }
          })
          .catch((err) =>
            setFormState({
              show: true,
              success: false,
              message: "Something went wrong",
            })
          )
          .finally(() => {
            setFormBusy(false);
          });
      }
    }
  };

  const resend = () => {
    setDisableResend(true);
    setFormState({ ...formState, show: false });
    resendEmail(formData.email)
      .then((data) => {
        if (data.status === "success") {
          setDisableResend(false);
          setFormState({
            show: true,
            success: true,
            message: "The verification email is sent",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableResend(false);
        setFormState({
          show: true,
          success: false,
          message: "Something went wrong",
        });
      });
  };

  return (
    <Container className="py-5">
      <div style={{ maxWidth: 400 }} className="mx-auto my-5 py-5">
        <Header as="h1" className="text-center">
          Make the most of Carmart
        </Header>
        <p className="text-center">
          It's completely <b>free</b>
        </p>
        <Form loading={formBusy}>
          <Form.Input
            className="mt-4"
            fluid
            value={formData.email}
            error={validationErr.email}
            name="email"
            placeholder="Email"
            onChange={handleChange}
          />
          <Form.Input
            className="mt-4"
            fluid
            value={formData.password}
            error={validationErr.password}
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
          />
          <Form.Input
            className="mt-4"
            fluid
            value={formData.cfrmPwd}
            error={validationErr.cfrmPwd}
            type="password"
            name="cfrmPwd"
            placeholder="Confirm Password"
            onChange={handleChange}
          />
          {formState.show && (
            <Message
              color={formState.success ? "green" : "red"}
              content={formState.message}
            />
          )}
        </Form>
        <Button fluid color="red" className="mt-2" onClick={handleSignup}>
          Register
        </Button>
        <Divider horizontal>OR</Divider>
        {showResend && (
          <div className="d-flex flex-column align-items-center mt-2">
            <p className="mt-1">
              Didn't receive the email?{" "}
              <Loader
                className="ml-2"
                size="small"
                active={disableResend}
                inline
              />
              {!disableResend && (
                <span
                  style={{ color: "#4183c4", cursor: "pointer" }}
                  onClick={resend}
                >
                  Resend
                </span>
              )}
            </p>
          </div>
        )}

        <div className="d-flex flex-column align-items-center mt-2">
          <p className="mt-1">
            Already have an account? <Link to="sign-in">Sign in</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Register;
