import CarSpecs from "../constants/car-specs";

export const getNewCarContextInitialValue = () => {
  if (window.location.href.includes("localhost:")) {
    return {
      reg: "GY12RZB",
      mileage: "1234",
      make: "ACURA",
      model: "MDX",
      variant: "AUTO",
      doorCnt: 4,
      fuelType: "Diesel",
      bodyType: "Coupe",
      engineSize: "1990",
      registrationDate: "2021-03-31",
      transmission: "Manual",
      color: "Cold",
      specs: ["Central Locking", "Air bag"],
      images: [
        "http://localhost:5000/public/cars/f5a4d372081b38242f28ae5c7bcb0092.png",
        "http://localhost:5000/public/cars/3f61bb89d50ca98609fd37146407e881.png",
        "http://localhost:5000/public/cars/f25f70322f0070c9ec4066675d56f093.png",
        "http://localhost:5000/public/cars/faa9bb50a61cc69ef0e5b4e5f1a66d53.jpg",
        "http://localhost:5000/public/cars/cd5e39b00e11c754f33412e90b951034.jpg",
        "http://localhost:5000/public/cars/aba4d80ec858afa3e15a360e683baba9.jpg",
        "http://localhost:5000/public/cars/a546a85d7165c3046d2b4ff90eb6a748.png",
        "http://localhost:5000/public/cars/69e337a7fcb007e1541c788a1ee9faf8.png",
        "http://localhost:5000/public/cars/a7498c11027a9de41303d0c12555ecb1.png",
      ],
      imageFiles: [],
      price: "4321",
      headline: "Acura MDX Auto Super Car",
      description: "Has central locking feature",
      postcode: "PE94SN",
      advertiser: {
        phone: "123456",
        email: "a@a.com",
        firstName: "MM",
        lastName: "BB",
        address: "test, ch",
      },
      motExpiryDate: "",
      year: "2014",
    };
  }
  return {
    reg: "",
    mileage: "",
    make: "",
    model: "",
    variant: "",
    doorCnt: "",
    fuelType: "",
    bodyType: "",
    engineSize: "",
    registrationDate: "",
    transmission: "",
    color: "",
    specs: [],
    images: ["", "", "", "", ""],
    imageFiles: [],
    price: "",
    headline: "",
    description: "",
    postcode: "",
    advertiser: {
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
      address: "",
    },
    motExpiryDate: "",
    year: "",
  };
};

export const getTransmissionOptions = (addAny = true) => {
  const options = [...CarSpecs.transmission];
  if (addAny)
    options.push({ key: "gb-unlisted", text: "Unlisted", value: "Unlisted" });
  return options;
};

export const getFuelTypeOptions = (addAny = true) => {
  const options = [...CarSpecs.fuelType];
  if (addAny)
    options.push({ key: "ft-unlisted", text: "Unlisted", value: "Unlisted" });
  return options;
};

export const getColorOptions = (addAny = true) => {
  const options = [...CarSpecs.color];
  if (addAny)
    options.push({ key: "at-unlisted", text: "Unlisted", value: "Unlisted" });
  return options;
};

export const getBodyTypeOptions = (addAny = true) => {
  const options = [...CarSpecs.bodyType];
  if (addAny)
    options.push({ key: "bt-unlisted", text: "Unlisted", value: "Unlisted" });
  return options;
};
