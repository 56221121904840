export default {
  distances: [
    { key: "dist-0", text: "National - Anywhere", value: 0 },
    { key: "dist-25", text: "Within 25 miles", value: 25 },
    { key: "dist-50", text: "Within 50 miles", value: 50 },
    { key: "dist-75", text: "Within 75 miles", value: 75 },
    { key: "dist-100", text: "Within 100 miles", value: 100 },
    { key: "dist-150", text: "Within 150 miles", value: 150 },
    { key: "dist-175", text: "Within 175 miles", value: 175 },
    { key: "dist-200", text: "Within 200 miles", value: 200 },
  ],
  transmission: [
    { key: "gb-auto", text: "Automatic", value: "Automatic" },
    { key: "gb-manu", text: "Manual", value: "Manual" },
  ],
  fuelType: [
    { key: "ft-bi", text: "Bi Fuel", value: "Bi Fuel" },
    { key: "ft-diesel", text: "Diesel", value: "Diesel" },
    { key: "ft-elec", text: "Electric", value: "Electric" },
    { key: "ft-hyb", text: "Hybrid", value: "Hybrid" },
    { key: "ft-petrol", text: "Petrol", value: "Petrol" },
  ],
  bodyType: [
    { key: "bt-convertible", text: "Convertible", value: "Convertible" },
    { key: "bt-coupe", text: "Coupe", value: "Coupe" },
    { key: "bt-estate", text: "Estate", value: "Estate" },
    { key: "bt-hatchbook", text: "Hatchback", value: "Hatchback" },
    { key: "bt-mpv", text: "MPV", value: "MPV" },
    { key: "bt-other", text: "Other", value: "Other" },
    { key: "bt-pickup", text: "Pickup", value: "Pickup" },
    { key: "bt-suv", text: "SUV", value: "SUV" },
    { key: "bt-saloon", text: "Saloon", value: "Saloon" },
  ],
  advertType: [
    { key: "at-private", text: "Private adverts", value: "Private" },
    { key: "at-trade", text: "Trade adverts", value: "Trade" },
    { key: "at-both", text: "Both", value: "Any" },
  ],
  color: [
    { key: "at-beige", text: "Beige", value: "Private" },
    { key: "at-black", text: "Black", value: "Black" },
    { key: "at-blue", text: "Blue", value: "Blue" },
    { key: "at-bronze", text: "Bronze", value: "Bronze" },
    { key: "at-brown", text: "Brown", value: "Brown" },
    { key: "at-burgundy", text: "Burgundy", value: "Burgundy" },
    { key: "at-gold", text: "Gold", value: "Gold" },
    { key: "at-green", text: "Green", value: "Green" },
    { key: "at-grey", text: "Grey", value: "Grey" },
    { key: "at-indigo", text: "Indigo", value: "Indigo" },
    { key: "at-magenta", text: "Magenta", value: "Magenta" },
    { key: "at-maroon", text: "Maroon", value: "Maroon" },
    { key: "at-multicolour", text: "Multicolour", value: "Multicolour" },
    { key: "at-navy", text: "Navy", value: "Navy" },
    { key: "at-orange", text: "Orange", value: "Orange" },
    { key: "at-pink", text: "Pink", value: "Pink" },
    { key: "at-purple", text: "Purple", value: "Purple" },
    { key: "at-red", text: "Red", value: "Red" },
    { key: "at-silver", text: "Silver", value: "Silver" },
    { key: "at-turquoise", text: "Turquoise", value: "Turquoise" },
    { key: "at-white", text: "White", value: "White" },
    { key: "at-yellow", text: "Yellow", value: "Yellow" },
  ],
  insuranceGroup: [
    { key: "ig-10", text: "Up to 10", value: 10 },
    { key: "ig-20", text: "Up to 20", value: 20 },
    { key: "ig-30", text: "Up to 30", value: 30 },
    { key: "ig-40", text: "Up to 40", value: 40 },
  ],
  co2Emssions: [
    { key: "co2-0", text: "Up to 0g/km CO2", value: 0 },
    { key: "co2-75", text: "Up to 75g/km CO2", value: 75 },
    { key: "co2-100", text: "Up to 100g/km CO2", value: 100 },
    { key: "co2-110", text: "Up to 110g/km CO2", value: 110 },
    { key: "co2-120", text: "Up to 120g/km CO2", value: 120 },
    { key: "co2-130", text: "Up to 130g/km CO2", value: 130 },
    { key: "co2-140", text: "Up to 140g/km CO2", value: 140 },
    { key: "co2-150", text: "Up to 150g/km CO2", value: 150 },
    { key: "co2-165", text: "Up to 165g/km CO2", value: 165 },
    { key: "co2-175", text: "Up to 175g/km CO2", value: 175 },
    { key: "co2-185", text: "Up to 185g/km CO2", value: 185 },
    { key: "co2-200", text: "Up to 200g/km CO2", value: 200 },
    { key: "co2-225", text: "Up to 225g/km CO2", value: 225 },
    { key: "co2-255", text: "Up to 255g/km CO2", value: 255 },
    { key: "co2-260", text: "255g/km +", value: 260 },
  ],
  doors: [
    { key: "door-2", text: "2 doors", value: 2 },
    { key: "door-3", text: "3 doors", value: 3 },
    { key: "door-4", text: "4 doors", value: 4 },
    { key: "door-5", text: "5 doors", value: 5 },
    { key: "door-6", text: "6 doors", value: 6 },
    { key: "door-10", text: "10 doors", value: 10 },
  ],
  acceleration: [
    { key: "accel-5", text: "0-5s(0-60mph)", value: 5 },
    { key: "accel-8", text: "0-8s(0-60mph)", value: 8 },
    { key: "accel-12", text: "8-12s(0-60mph)", value: 12 },
    { key: "accel-12+", text: "12s+(0-60mph)", value: 13 },
  ],
  fuelConsumption: [
    { key: "fc-30", text: "30+ mpg", value: 30 },
    { key: "fc-40", text: "40+ mpg", value: 40 },
    { key: "fc-50", text: "50+ mpg", value: 50 },
    { key: "fc-60", text: "60+ mpg", value: 60 },
  ],
  specifications: [
    {
      name: "Secuirty and Safety",
      specs: [
        { name: "Central Locking", idx: 0 },
        { name: "Standard Alarm", idx: 1 },
        { name: "Thatcham Approved Alarm", idx: 2 },
        { name: "Air bag", idx: 3 },
        { name: "Isofix Child Seat", idx: 4 },
        { name: "Tracking Service", idx: 5 },
      ],
    },
    {
      name: "Interior Features",
      specs: [
        { name: "Electric Windows", idx: 6 },
        { name: "CD Player", idx: 7 },
        { name: "Digital Radio", idx: 8 },
        { name: "Leather Seats", idx: 9 },
        { name: "Reat TV", idx: 10 },
        { name: "Sunroof", idx: 11 },
      ],
    },
    {
      name: "Exteriror Features",
      specs: [
        { name: "Alloy Wheels", idx: 12 },
        { name: "Tinted Windows", idx: 13 },
        { name: "Rear Spoiler", idx: 14 },
        { name: "Metalic Paint", idx: 15 },
      ],
    },
    {
      name: "Driver Convenience",
      specs: [
        { name: "Sat Nav", idx: 16 },
        { name: "Parking Sensors", idx: 17 },
        { name: "Self Parking", idx: 18 },
        { name: "Head up Display", idx: 19 },
      ],
    },
  ],
  sortOptions: [
    { key: "so-0", text: "Relevance", value: "relevance" },
    { key: "so-1", text: "Price(Lowest)", value: "pricel" },
    { key: "so-2", text: "Price(Highest)", value: "priceh" },
  ],
  price: [
    { key: "pr-any", text: "Any", value: 0 },
    { key: "pr-0", text: "£0", value: 0 },
    { key: "pr-500", text: "£500", value: 500 },
    { key: "pr-1000", text: "£1,000", value: 1000 },
    { key: "pr-1500", text: "£1,500", value: 1500 },
    { key: "pr-2000", text: "£2,000", value: 2000 },
    { key: "pr-2500", text: "£2,500", value: 2500 },
    { key: "pr-3000", text: "£3,000", value: 3000 },
    { key: "pr-3500", text: "£3,500", value: 3500 },
    { key: "pr-4000", text: "£4,000", value: 4000 },
    { key: "pr-4500", text: "£4,500", value: 4500 },
    { key: "pr-5000", text: "£5,000", value: 5000 },
    { key: "pr-5500", text: "£5,500", value: 5500 },
    { key: "pr-6000", text: "£6,000", value: 6000 },
    { key: "pr-6500", text: "£6,500", value: 6500 },
    { key: "pr-7000", text: "£7,000", value: 7000 },
    { key: "pr-7500", text: "£7,500", value: 8500 },
    { key: "pr-8000", text: "£8,000", value: 8000 },
    { key: "pr-8500", text: "£8,500", value: 8500 },
    { key: "pr-9000", text: "£9,000", value: 9000 },
    { key: "pr-9500", text: "£9,500", value: 9500 },
    { key: "pr-10000", text: "£10,000", value: 10000 },
    { key: "pr-11000", text: "£11,000", value: 11000 },
    { key: "pr-12000", text: "£12,000", value: 12000 },
    { key: "pr-13000", text: "£13,000", value: 13000 },
    { key: "pr-14000", text: "£14,000", value: 14000 },
    { key: "pr-15000", text: "£15,000", value: 15000 },
    { key: "pr-16000", text: "£16,000", value: 16000 },
    { key: "pr-17000", text: "£17,000", value: 17000 },
    { key: "pr-18000", text: "£18,000", value: 18000 },
    { key: "pr-19000", text: "£19,000", value: 19000 },
    { key: "pr-20000", text: "£20,000", value: 20000 },
    { key: "pr-22500", text: "£22,500", value: 22500 },
    { key: "pr-25000", text: "£25,000", value: 25000 },
    { key: "pr-27500", text: "£27,500", value: 27500 },
    { key: "pr-30000", text: "£30,000", value: 30000 },
    { key: "pr-35000", text: "£35,000", value: 35000 },
    { key: "pr-40000", text: "£40,000", value: 40000 },
    { key: "pr-45000", text: "£45,000", value: 45000 },
    { key: "pr-50000", text: "£50,000", value: 50000 },
    { key: "pr-55000", text: "£55,000", value: 55000 },
    { key: "pr-60000", text: "£60,000", value: 60000 },
    { key: "pr-65000", text: "£65,000", value: 65000 },
    { key: "pr-70000", text: "£70,000", value: 70000 },
    { key: "pr-75000", text: "£75,000", value: 75000 },
    { key: "pr-100000", text: "£100,000", value: 100000 },
    { key: "pr-250000", text: "£250,000", value: 250000 },
    { key: "pr-500000", text: "£500,000", value: 500000 },
    { key: "pr-1000000", text: "£1,000,000", value: 1000000 },
    { key: "pr-2000000", text: "£2,000,000", value: 2000000 },
  ],
  mileage: [
    { key: "0", text: "Any", value: 0 },
    { key: "mi-100", text: "Up to 100 miles", value: 100 },
    { key: "mi-500", text: "Up to 500 miles", value: 500 },
    { key: "mi-5000", text: "Up to 5,000 miles", value: 5000 },
    { key: "mi-10000", text: "Up to 10,000 miles", value: 10000 },
    { key: "mi-15000", text: "Up to 15,000 miles", value: 15000 },
    { key: "mi-20000", text: "Up to 20,000 miles", value: 20000 },
    { key: "mi-25000", text: "Up to 25,000 miles", value: 25000 },
    { key: "mi-30000", text: "Up to 30,000 miles", value: 30000 },
    { key: "mi-35000", text: "Up to 35,000 miles", value: 35000 },
    { key: "mi-40000", text: "Up to 40,000 miles", value: 40000 },
    { key: "mi-45000", text: "Up to 45,000 miles", value: 45000 },
    { key: "mi-50000", text: "Up to 50,000 miles", value: 50000 },
    { key: "mi-60000", text: "Up to 60,000 miles", value: 60000 },
    { key: "mi-70000", text: "Up to 70,000 miles", value: 70000 },
    { key: "mi-80000", text: "Up to 80,000 miles", value: 80000 },
    { key: "mi-90000", text: "Up to 90,000 miles", value: 90000 },
    { key: "mi-100000", text: "Up to 100,000 miles", value: 100000 },
    { key: "mi-125000", text: "Up to 125,000 miles", value: 125000 },
    { key: "mi-150000", text: "Up to 150,000 miles", value: 150000 },
    { key: "mi-200000", text: "Up to 200,000 miles", value: 200000 },
  ],
};
