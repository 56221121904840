import React, { createContext, useContext, useState } from "react";

export const carsContext = createContext({});

const CarsProvider = ({ children }) => {
  const [searchResult, setSearchResult] = useState({
    cars: [],
    totalCnt: 0,
  });

  return (
    <carsContext.Provider value={{ searchResult, setSearchResult }}>
      {children}
    </carsContext.Provider>
  );
};

export const useCars = () => useContext(carsContext);
export default CarsProvider;
