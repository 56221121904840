import React from "react";

function App() {
  return (
    <div className="comingsoon bgimg">
      <div className="middle">
        <h1>COMING SOON</h1>
        <hr />
        <p>35 days</p>
      </div>
    </div>
  );
}

export default App;
